import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../app/modules/auth'
import { HeaderUserMenu, KTSVG } from '../../../partials'

const btnClass = 'btn btn-sm btn-icon'

const Navbar = () => {
  const { currentUser } = useAuth()

  useEffect(() => {
    // console.log('currentUser', currentUser)
  }, [])

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className='d-flex align-items-center gap-2'>
        <div className='bg-success text-white h-35px w-35px rounded d-flex flex-center'>
          {(currentUser as any)?.store?.storecode}
        </div>
        <div className='d-flex flex-column'>
          <span>Cửa hàng</span>
          <span>{(currentUser as any)?.store?.storename}</span>
        </div>
        <button type='button' className={btnClass}>
          <KTSVG path='/media/icons/custom/bell2.svg' className='svg-icon-1 text-dark text-hover-primary' />
        </button>
        <button type='button' className={btnClass} data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach='parent' data-kt-menu-placement='bottom-end'>
          {currentUser?.avatar
            ? <img alt='avatar' src={currentUser?.avatar} style={{ objectFit: 'cover' }} />
            : <div className="symbol-label">
              <KTSVG path='/media/icons/custom/user2.svg' className='svg-icon-1 text-dark text-hover-primary' />
            </div>}
        </button>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Navbar }

