import { useEffect, useState } from 'react'
import { SideBarMenuData } from '../../../../../app/router/routerData'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const defaultIcon = '/media/icons/duotune/art/art002.svg'
  const [dataMenuRender,setDataMenuRender] = useState<any>([])
  useEffect(()=>{
    let getData = async() => {
      let data = await SideBarMenuData()
      setDataMenuRender(data)
    }
    getData()
  },[])
  function getPath(path?: string) {
    return path ? `/${path}` : ''
  }

  function getTitle(key: string) {
    return key//intl.formatMessage({ id: `sidebar.menu.${key}` })
    // return intl.formatMessage({ id: `sidebar.menu.${key}` })
  }
  return (
    <>
      {dataMenuRender?.map((item: any, i: number) => {
        const { title, children, path, icon, defaultOpen, isLink} = item
        return children
          ? <SidebarMenuItemWithSub
            key={i}
            to={children.length == 0 ? '#' : getPath(path)}
            title={getTitle(title)}
            icon={icon || defaultIcon}
            defaultOpen={defaultOpen}
            isLink={isLink}
          >
            <div className='ps-4'>
              {children
                .map((sub: any, iSub: number) =>
                  <SidebarMenuItem
                    key={iSub}
                    to={getPath(path) + getPath(sub.path)}
                    title={getTitle(`${sub.title}`)}
                    icon={sub.icon || defaultIcon} />
                )}
            </div>
          </SidebarMenuItemWithSub>
          : <SidebarMenuItem
            key={i}
            to={getPath(path)}
            title={getTitle(title)}
            icon={icon || defaultIcon}
          />
      })}
    </>
  )
}

export { SidebarMenuMain }

