import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, formatCurrent, } from "../../../../theme/helpers";
import { KTButton, SearchTable } from "../../../../theme/partials";
import { getAuth, useAuth } from "../../../modules/auth";
import { useStoreMode, useStorePrint } from "../../../modules/auth/core/store";
import { popupMessage } from "../../../modules/messages";
import { ModalAddEditOrder } from "./addOrder";
import { cancelOrder, converDataWithCustom, funcSend, handleClickGetItemKOT } from "./functionOrder";
import { DetailItem, funcAddNote, HeadItemTemplate, KITCHENSTATUS, MemberInforAndNote, ModalMessage, ModalPay, NoDataTemplate, PromotionAndSum } from "./templateOrder";
import { useTranslation, Trans } from "react-i18next";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});
const badgeSearch = [{
    label:  <Trans i18nKey="orders-hub-deep.not-paid"/>,
    value: "notPaid",
},
{
    label:  <Trans i18nKey="orders-hub-deep.paid"/>,
    value: "isPaid",
},
{
    label: <Trans i18nKey="orders-hub-deep.dinner-in"/>,
    value: "dinner_in",
},
{
    label: <Trans i18nKey='orders-hub-deep.curbside'/>, //"Cubsude",
    value: "is_curbside",
},
{
    label: <Trans i18nKey='orders-hub-deep.delivery'/>,
    value: "is_delivery",
},
{
    label: <Trans i18nKey='orders-hub-deep.no-behavior'/>,
    value: "no_behavior",
},
{
    label: <Trans i18nKey='orders-hub-deep.takeout'/>,
    value: "takeout_in",
},
]
const urlApi = 'settings/customers'
export const checkPaid = (item: any,field?: string) => {
    let paymentField = 'TransactionPaymentEntries'
    if(field) {
        paymentField = field
    }
    let grossamount = +item.grossamount
    let amounttendered = 0
    let badge = {
        label: <Trans i18nKey='orders-hub-deep.not-paid'/>, // 'Not Paid',
        badge: 'light-danger',
        text: 'white',
        isPaid: false,
        paided: false,
        amount: grossamount - amounttendered
    } as any
    if(item[paymentField] && item[paymentField].length != 0) {
        amounttendered = item[paymentField].filter((i: any) => !i.ischangeline).reduce(function (acc: any, obj: any) { return acc + +obj.amounttendered; }, 0);
    }
    else {
        return badge
    }
    if(amounttendered != 0 && amounttendered >= grossamount) {
        badge = {
            label: <Trans i18nKey='orders-hub-deep.paid'/>, //'Paid',
            badge: 'light-primary',
            text: 'white',
            isPaid: true,
            paided: true,

        }
    }
    else if(amounttendered != 0 && amounttendered < grossamount) {
        badge = {
            label: <Trans i18nKey='orders-hub-deep.partially-paid'/>,//'Partially Paid',
            badge: 'light-info',
            text: 'info',
            amount: grossamount - amounttendered,
            isPaid: false,
            paided: true,
        }
    }
    return badge
}
const ActiveOrder = (props:any) => {
    const { t } = useTranslation()
    const {dataApi,filter,setFilter, isSearch, setSearchOn, dataNotify, isClickTab, setIsClickTab} = props
    const callBackReFetch = async() => {
        await handleClickGetItemKOT(dataQuery[orderActive],setDataActive,setNote,setIsloading, orderActive,dataQuery, setDataQuery)
        // console.log('data')
        
    }
    
    // const { permission } = getLocationData()
    const navigate = useNavigate();
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(0)
    const [edit ,setEdit] = useState<any>(false)
    const [isShowNote,setIsShowNote] = useState(false)
    const [isloading,setIsloading] = useState(false)
    
    const [note,setNote] = useState('')
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}
    let storePrint = useStorePrint((e: any)=> e)||{}
    const [user,setUser] = useState<any>({})
    useEffect(()=> {
        const getInitalAuth = async() => {
            const dataAuth = await getAuth()||{} as any
            setUser(dataAuth.user||{})
        }
        getInitalAuth()
    },[])
        
    useEffect(() => {
        if(dataApi) {
            getData(dataApi||[])
        }
    }, [dataApi])
    useEffect(() => {
        if(props.filter) {
        }
    }, [props.filter])
    const getData = (data: any) => {
        if(isClickTab) {
            setDataActive(null)
            setOrderActive(-1)
            setIsClickTab(false)
        }
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
        })
       
        if(data.length == 0) {
            setDataActive(null)
            setOrderActive(-1)
        }
        else if(!dataActive) {
            handleClickGetItemKOT(data[0],setDataActive,setNote,setIsloading)
            setOrderActive(0)
        }
        else if(dataActive && dataNotify && dataActive.id == dataNotify?.id) {
            if(!edit) {
                handleClickGetItemKOT(dataActive,setDataActive,setNote,setIsloading)
            }
        }
        else {
            handleClickGetItemKOT(data[0],setDataActive,setNote,setIsloading)
            setOrderActive(0)
        }
        setDataQuery(data)
      
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })

    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    const actionSend = () => {
        funcSend(dataActive,setDataActive,user,staffInfo, storePrint)
    }
    const funcCallback = async() => {
      
        let tempData = dataQuery.map((i: any) => i)
        let status = dataActive?.nextStatus
        // console.log('status',status)
        // return
        try {
            const urlApi = `smarthub/hosptransactions/update/${dataActive?.id}`
            let tempApproved = {
                transactionstatus: status,
                staffcode: staffInfo.code,
                staffreceiptname: staffInfo.nameonreceipt,
            }
            await ApiService.put(urlApi, tempApproved)
            // callBackReFetch()
            tempData = tempData.filter((i: any,ind: number) => ind != orderActive)
            // setDataQuery(tempData)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }
    
    const checkSearch = (value: any) => {
        return filter[value]
    }
    const [showPayTemplate,setShowPayTemplate] = useState(false)
    const [dataActive,setDataActive] = useState<any>(null)
    
    return <>
    <div className="row">
        <div className="col-7 ps-0">
        <form
            className="h-100 w-100"
            onSubmit={handleSubmit(async (data: any) => {
                // console.log('onsubmit',data)
                return
                
            })}>
            <div className="wrap-content-card h-100">
                {isSearch && <div className="position-relative mt-4">
                    
                    <SearchTable data={dataQuery} setTableData={setDataQuery} />
                    <span className="cursor-pointer close-event-s" onClick={e => setSearchOn(false)}>Cancel</span>
                </div>
                }
                {filter &&
                    <div className="card-header min-h-auto pt-4 d-flex gap-2">
                        {
                            badgeSearch.map((item: any) => (
                                checkSearch(item.value) && 
                                <span className="badge badge-gray d-inline-flex flex-center py-2 gap-1"><span>{item.label}</span> <i onClick={e=>{
                                    let a = JSON.parse(JSON.stringify(filter))
                                    a[item.value] = false
                                    setFilter(a)
                                }} className="bi bi-x cursor-pointer"></i></span>
                            ))
                        }
                        
                    </div>
                }
                { dataQuery && dataQuery.length !=0 ?
                <div className="card-body pb-0 pt-4">
                    {dataQuery?.map((item: any,index: number) =>{
                        return <div className="wrap-item" key={index} onClick={e=>{
                            if(orderActive == index) {
                                return
                            }
                            setOrderActive(index)
                            handleClickGetItemKOT(item,setDataActive,setNote,setIsloading)
                        }}>
                            <HeadItemTemplate item={item} index={index} orderActive={orderActive} isTagPaid={true} checkPaid={checkPaid(item)}/>
                        </div>
                    })}
                </div>
                :
                <NoDataTemplate className={'h-100 pt-4'}/>
                }
                
            </div>
            
            
            
        </form>
        </div>
        <div className="col-5 right-detail px-0">
            {dataActive && orderActive > -1 ? 
                <div className="p-0 card shadow">
                    <h6 className="d-flex flex-stack px-3 pt-3">
                        <span>{dataActive.tablename}</span>
                        {
                            (staffPermissionGroupInfo.isvoidtransaction || staffPermissionGroupInfo.isvoiditemsenttokitchen) && !checkPaid(dataActive).paided &&
                            <span onClick={e=>cancelOrder(dataActive,orderActive,dataQuery,setDataQuery, staffInfo)} className="btn btn-sm btn-icon btn-light btn-active-light-danger"><i className="bi bi-x-lg"></i></span>
                        }
                    </h6>
                    <div className={clsx({"loading-calculate":isloading})}>
                        {
                            isloading &&
                            <div className="bg-blur"><div className="loader-cal"></div></div>
                        }
                        <DetailItem {...dataActive} note={note} className="wrap-hub-details" {...dataActive}/>
                        <div className="wrap-footerItem">
                            <MemberInforAndNote note={note} {...dataActive} setDataModal={setDataActive} />
                            <PromotionAndSum {...dataActive} note={note}/> 
                            
                            <div className="d-flex gap-3 mt-3 px-3">
                                {/* <button className={clsx("btn btn-icon btn-sm btn-success w-100",)} onClick={e=>{
                                    setIsShowNote(true)
                                }}><i className="bi bi-chat-right me-2"></i>{t('orders-hub-deep.kot-comments')}</button> */}
                                {!checkPaid(dataActive).isPaid ?
                                <>
                                {
                                    staffPermissionGroupInfo.isopensalepos  &&
                                    <button onClick={e=>setEdit(true)} className="btn btn-icon btn-sm btn-primary w-100"><i className="bi bi-pencil me-1"></i>{t('orders-hub-deep.update')}</button>
                                }
                                {staffPermissionGroupInfo.isaddpayment &&
                                    <button onClick={e=>setShowPayTemplate(true)} className="btn btn-sm btn-warning w-100">{t('orders-hub-deep.pay')} {formatCurrent(checkPaid(dataActive).amount)}</button>
                                }
                                </>
                                :
                                <>
                                    {
                                        dataActive?.HospTransactionLines?.find((i:any)=> i.kitchenstatus == KITCHENSTATUS.notSent)?
                                        <button onClick={actionSend} className="btn btn-sm btn-info w-100"><i className="bi bi-send-fill me-1"></i>{t('order-deep.send')}</button>
                                        :
                                        <button onClick={funcCallback} className="btn btn-sm btn-info w-100"><i className="bi bi-building-check me-1"></i>{t('orders-hub-deep.order-ready')}</button>
                                    }                    
                                </>

                                
                                }
                            </div>
                            <div className="p-3">
                                {!checkPaid(dataActive).isPaid &&
                                
                                    <>
                                    {
                                        dataActive?.HospTransactionLines?.find((i:any)=> i.kitchenstatus == KITCHENSTATUS.notSent)?
                                        <button onClick={actionSend} className="btn btn-sm btn-info w-100"><i className="bi bi-send-fill me-1"></i>{t('order-deep.send')}</button>
                                        :
                                        <button onClick={funcCallback} className="btn btn-sm btn-info w-100"><i className="bi bi-building-check me-1"></i>{t('orders-hub-deep.order-ready')}</button>
                                    }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                :
                <NoDataTemplate />
            }
            
        </div>
    </div>
    {
        staffPermissionGroupInfo.isaddpayment &&
        <ModalPay show={showPayTemplate} refetchCurrent={callBackReFetch} dataModal={dataActive} setDataModal={setDataActive} setShow={setShowPayTemplate} />
    }
    {
        edit && dataActive?.id &&
        <ModalAddEditOrder refetchCurrent={callBackReFetch}  show={edit} dataModal={dataActive} setDataModal={setDataActive} setShow={setEdit}/>
    }
    <ModalMessage isShowNote={isShowNote} setIsShowNote={setIsShowNote} setNote={setNote} callFuncAddMess={funcAddNote} ids={dataActive?.kots?.map((i: any) => i.id)||[]} idHosp={dataActive?.id}/>
        
    </>
}

export { ActiveOrder };

