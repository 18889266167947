import { ReactNode, FC, useRef } from "react"
import { Dropdown } from "react-bootstrap"
import { UseFormHandleSubmit } from "react-hook-form";
import { KTButton } from "../widgets";
import { Trans } from "react-i18next";

interface IFilterDropdown {
    btnRef?: React.MutableRefObject<HTMLButtonElement | null>
    children: ReactNode
    onClose?: () => void
    onSubmit?: (data: any) => void
    handleSubmit?: UseFormHandleSubmit<any>
    width?: string
    btnTemplate?: any
}

export const FilterDropdown: FC<IFilterDropdown> = ({ handleSubmit, children, onClose, onSubmit, width, btnTemplate }) => {
    const btnFilter = useRef<HTMLButtonElement | null>(null)

    if (!handleSubmit || !onSubmit) return <></>
    return <Dropdown align={{ lg: 'end' }} >
        <Dropdown.Toggle ref={btnFilter} as='span' className="d-none-after">
            {btnTemplate ? btnTemplate : <KTButton action="filter" hiddenTitle={true} />}
        </Dropdown.Toggle>
        <Dropdown.Menu className='min-w-250px p-0' style={{ width: width ? width + 'px' : 'auto' }}>
            <Dropdown.Header as="div" className="d-flex flex-stack align-items-end pt-3 border-gray-300 border-bottom">
                <h4 className="m-0"><Trans i18nKey="filter-deep.title" /></h4>
            </Dropdown.Header>
            <Dropdown.ItemText>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex flex-column">
                        {children}
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        {onClose && <button
                            type="button"
                            className="btn btn-sm btn-light-gray btn-custom border border-2 border-gray"
                            onClick={() => {
                                btnFilter.current?.click()
                                onClose()
                            }}
                        >
                            <Trans i18nKey="filter-deep.cancel" />
                        </button>}
                        <button
                            type="submit"
                            className="btn btn-sm btn-light-primary btn-custom border border-2 border-primary"
                            onClick={() => btnFilter.current?.click()}
                        >
                            <Trans i18nKey="filter-deep.apply" />
                        </button>
                    </div>
                </form>
            </Dropdown.ItemText>
        </Dropdown.Menu>
    </Dropdown>
}