import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 overflow-hidden fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-5'>
        <div className='mb-2 px-10'>
          <img src="/media/images/logos/logo.svg" className='w-150px' alt="loginimage" />
        </div>
        <h3 className='text-center text-white fs-2x fw-bolder text-uppercase'>
          Forgot password
        </h3>
        <hr className='h-2px bg-white'/>
      </div>
      {/* begin::Form group */}
      <div className='w-lg-25 m-auto mt-lg-20'>
        {/* <label className='form-label fw-bolder text-gray-900 fs-6'>Username/ Email</label> */}
        <input
          type='email'
          placeholder='Email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent border-white p-5 text-white border border-2',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
        <div className='text-center d-grid mt-4'>
          <button type='submit' id='kt_password_reset_submit' className='mb-5 btn btn-sm bg-white p-5 text-primary fw-bolder'>
            <span className='indicator-label'>SEND REQUEST</span>
            {loading && (
              <span className='indicator-progress'>
              Please waitting...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/login'>
            <span className='text-orange fw-bolder'>Back to login?</span>
          </Link>
        </div>
      </div>
    </form>
  )
}
