import { FC, ReactNode } from 'react';
import { AuthModel, getAuth, useAuth } from '.';
import { decryptData } from '../../../theme/helpers/utils';

export const framePermission: any = [{
    value: 'DELETE', code: 'deletepermission'
}, {
    value: 'EXECUTE', code: 'executepermission'
}, {
    value: 'INSERT', code: 'insertpermission'
}, {
    value: 'MODIFY', code: 'modifypermission'
}, {
    value: 'READ', code: 'readpermission'
}]

function getPermissions(auth: AuthModel | undefined, permission: string) {
    let userPermissions: any = decryptData(auth?.permissions as any)
    userPermissions = JSON.parse(userPermissions)
    return userPermissions.filter((item: any) => item.code == permission)
}

interface IShowForPermission {
    permission: string[]
    children?: ReactNode
}

export const ShowForPermission: FC<IShowForPermission> = (props) => {
    const { auth } = useAuth()
    if (!auth || !auth.user?.rolecode) return <></>
    if (decryptData(auth?.user?.rolecode) == 'SUPER') return <>{props.children}</>
    let couldShow = false
    let listPermission = getPermissions(auth, props.permission[0])
    if (listPermission && listPermission.length != 0) {
        const permissions = listPermission[0].permissions
        if (permissions && permissions instanceof Array) {
            if (props.permission && props.permission.length > 0) {
                couldShow = permissions.some(per => [props.permission[1]].includes(per))
            }
        }
    }
    return <>{couldShow && props.children}</>
};

// ['10001', 'UPDATE', 'DELETE]
export const getIsPermission = async(permission: string[], type?: 'or' | 'and') => {
    const auth = await getAuth()
    if (!auth || !auth.user?.rolecode) return false
    if (decryptData(auth?.user?.rolecode) == 'SUPER') return true
    let listPermission = getPermissions(auth, permission[0])

    if (listPermission && listPermission.length != 0) {
        const permissions = listPermission[0].permissions
        if (permissions && permissions instanceof Array) {
            if (permission && permission.length > 0) {
                const permissionRoles = permission.filter((e: any, i: number) => i != 0)

                return type == 'or'
                    ? permissionRoles.some(per => permissions.includes(per))
                    : permissionRoles.every(per => permissions.includes(per))
            }
        }
    }
    return false
}

export const ShowForSuperAdmin = ({ children }: { children: ReactNode }) => {
    const { auth } = useAuth()
    if (!auth || !auth.user?.rolecode) return <></>

    const roleCode = decryptData(auth?.user.rolecode)
    return <>{roleCode == "SUPER" && children}</>
}

interface IShowForRole {
    role: string
    children?: ReactNode
}

export const ShowForRole: FC<IShowForRole> = (props) => {
    const { auth } = useAuth() as any
    if (!auth) return <></>

    const roleCode: any = decryptData(auth.user.rolecode)
    let couldShow = roleCode == props.role

    return <>{couldShow && props.children}</>
};