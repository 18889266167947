import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core'
import { MasterInit } from '../theme/layout/MasterInit'

export function App() {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  return <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <Outlet />
        <MasterInit />
      </LayoutProvider>
  </Suspense>
}
