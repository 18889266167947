import localforage from "localforage";
import { formatCurrent } from "../../../../theme/helpers";
import { popupMessage } from "../../../modules/messages";

export const orderPrintEpson = async(printer: any, ePosDev: any, data: any) => {
  let TransactionPaymentEntries = data.TransactionPaymentEntries||[]
  let infoSetupPrint = await localforage.getItem("merchantsData")||{} as any
  let {receiptsetups} = infoSetupPrint||{}
  if (!printer) {
    popupMessage({icon:'info',title: 'Failed!!', description: 'Failed to connect to printer'});
    return;
  }

  printer.addLayout(printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 15, 15);
  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextSmooth(true);
  // printer.addText('\n');

  printer.addTextSize(2,2)
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addTextLineSpace(10)
  printer.addText(data.merchantname + '\n');
  printer.addTextSize(1,1)
  if(receiptsetups?.header) {
    printer.addText(receiptsetups?.header);
  }
  printer.addTextStyle(false, false, false, undefined);//text normal

  printer.addTextLineSpace(10)
  printer.addTextDouble(false, false);
  printer.addText(`Địa chỉ: ` + data.merchantname+ '\n');
  printer.addText('\n');

  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText(`HÓA ĐƠN` + '\n');
  printer.addText(`Số HĐ: `);
  printer.addText( data.code+ '\n');



  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Ngày: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  let date = new Date().toLocaleString('en-GB', { hour12: false })//data.transactiondate+' '+data.transactiontime
  printer.addText(date + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  // printer.addText('Bàn: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.sectionname + ' - ');
  printer.addText(data.tablename + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Phục vụ: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.staffcode + '\n');

  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Thu ngân: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.staffreceiptname + '\n');

  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('KH: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.customerinfo?.name + '\n');

  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('SĐT: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText('không có' + '\n');
  printer.addTextAlign(printer.ALIGN_LEFT);
  // Bảng tiêu đề
  printer.addText("+-----------------+----+---------+------------+\n");
  printer.addText("| Tên món         | SL | Đơn giá | Thành tiền |\n");
  printer.addText("+-----------------+----+---------+------------+\n");
  
  let items  = data?.HospTransactionLines||data.HospTransactionLines
  items.forEach((item: any,index: number) => {
    const itemLines = splitItemName(`${item.itemname}`, 15);
    itemLines.forEach((line: any, index: number) => {
      if (index === 0) {
        printer.addText(formatItemLine(line, item.quantity.toString(), formatCurrent(item.price), formatCurrent(item.amount)));
      } else {
        printer.addText(formatItemLine(line, '', '',''));
      }
    });
    printer.addText("+-----------------+----+---------+------------+\n");
    if(item.customizations && item.customizations.length != 0) {
      let toppings = item.customizations
      toppings.forEach((i: any,index: number) => {
        const itemLinesTopping = splitItemName(`${i.description}`, 15);
        itemLinesTopping.forEach((line: any, index: number) => {
          if (index === 0) {
            printer.addText(formatItemLine(line, i.quantity.toString(), formatCurrent(i.price), formatCurrent(i.amount)));
          } else {
            printer.addText(formatItemLine(line, '', '',''));
          }
        });
        printer.addText("+-----------------+----+---------+------------+\n");
      });
    }
    
    else {
        // printer.addText("+-----------------+----+---------+------------+\n");
    }
  });
  formatTextLeftRight(printer,'Tổng thanh toán',formatCurrent(data.grossamount))
  if(TransactionPaymentEntries && TransactionPaymentEntries.length != 0) {
    printer.addText("-----------------------------------------------\n");
    TransactionPaymentEntries.forEach((item: any) => {
      if(!item.ischangeline) {
        let textL = item.tendertypename == 'Cash' ? 'Tiền mặt' : item.tendertypename
        let amounttendered = TransactionPaymentEntries.reduce(function (acc: any, obj: any) { return acc + Math.abs(+obj.amounttendered); }, 0)
        let textR = formatCurrent(amounttendered)
        formatTextLeftRight(printer,textL,textR)
      }
      else {
        let textL = 'Trả lại khách'
        let textR = formatCurrent(Math.abs(+item.amounttendered))
        formatTextLeftRight(printer,textL,textR)
      }
    })
  }
  if(receiptsetups?.footer) {
    printer.addText(receiptsetups?.footer);
  }
  if(receiptsetups?.logo) {
    printer.drawImage(receiptsetups?.logo);
  }
  printer.addCut(printer.CUT_FEED);
  printer.send();
};
export const kitchenPrintEpson = async(printer: any, ePosDev: any, data: any,isDisconnect?: any) => {
  // let TransactionPaymentEntries = data.TransactionPaymentEntries||[]

  if (!printer) {
    popupMessage({icon:'info',title: 'Failed!!', description: 'Failed to connect to printer'});
    return;
  }

  printer.addLayout(printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 15, 15);
  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextSmooth(true);

  printer.addTextSize(2,2)
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addTextLineSpace(10)
  printer.addText(data.sectionname + ' - '+ data.tablename + '\n');
  printer.addTextDouble(false, false);
  printer.addTextSize(1,1)
  printer.addText(data.name?.toUpperCase() + '\n');
  printer.addTextStyle(false, false, false, undefined);//text normal

  printer.addTextLineSpace(10)

  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Order: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.code + '\t');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Ngày: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  let date = new Date().toLocaleString('en-GB', { hour12: false })//data.transactiondate+' '+data.transactiontime
  printer.addText(date + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('SL khách: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(`${data.noofcovers?data.noofcovers:''}` + '\t');
  // printer.addTextStyle(false, false, true, undefined);//bold
  // printer.addText('Bàn: ');
  // printer.addTextStyle(false, false, false, undefined);//text normal
  // printer.addText(data.tablename + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Phục vụ: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.nameonreceipt + '\n');

  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Ghi chú: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(`${data.message?data.message:''}` + '\n');
  let {dataInfo} = data
  console.log('dataInfo',dataInfo)
  if(dataInfo) {
    if(dataInfo.title) {
      printer.addTextAlign(printer.ALIGN_CENTER);
      printer.addTextStyle(false, false, true, undefined);//bold
      printer.addTextSize(2,2)
      printer.addText(`**${dataInfo.title}**\n`);
      printer.addTextSize(1,1)
      printer.addTextStyle(false, false, false, undefined);//text normal
      if(dataInfo.description) {
        printer.addText(dataInfo.description+'\n');
      }
      if(dataInfo.staffInfo) {
        let note = `Bởi ${dataInfo.staffInfo.code}`
        if(note) {
          printer.addTextStyle(false, false, false, undefined);//bold
          printer.addText(`(${note})\n`);
          printer.addTextStyle(false, false, false, undefined);//text normal
        }
      }
      // printer.addTextAlign(printer.ALIGN_LEFT);
    }
  }
  printer.addTextAlign(printer.ALIGN_LEFT);
  // Bảng tiêu đề
  printer.addText("+---------------------------------------------+\n");
  printer.addText("| ");
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText("Món");
  printer.addText("                                      SL ");
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText("|\n");

  printer.addText("+---------------------------------------------+\n");
  let items  = data?.HospTransactionLines||data.HospTransactionLines
  items.forEach((item: any,index: number) => {
    let itemname = item.description||item.itemname
    // let leng = 0
    let prefix = item.prefix
    if(item.prefix) {
      if( item.prefix == 'GỘP BÀN' || item.prefix == 'CHUYỂN BÀN') {
        prefix = 'CHUYỂN BÀN'
      }
      itemname = `*${prefix}* ${itemname}`
    }
    let itemLines = splitItemName(`${itemname}`, 37);
    itemLines.forEach((line: any, index: number) => {

      if (index === 0) {
        formatItemLineKitchenPrinter(printer,prefix, line, item.quantity.toString())
        // printer.addText(formatItemLineKitchen(line, item.quantity.toString()));
      } else {
        printer.addText(formatItemLineKitchen(line, '',));
      }
    });
    
    // printer.addText("+---------------------------------------------+\n");
    if(item.customizations && item.customizations.length != 0) {
      let toppings = item.customizations
      toppings.forEach((i: any,index: number) => {
        const itemLinesTopping = splitItemName(`${i.description}`, 37);
        itemLinesTopping.forEach((line: any, index: number) => {
          if (index === 0) {
            printer.addText(formatItemLineKitchen(line, i.quantity.toString(), 2));
          } else {
            printer.addText(formatItemLineKitchen(line, '', 2));
          }
        });
      });
      if(!item.message) {
        printer.addText("-----------------------------------------------\n");
      }

    }
    if(item.message) {
      printer.addText(formatItemLineKitchen(item.message, '', 2,'*'));

    }
    if(item.textNote) {
      printer.addTextStyle(false, false, true, undefined);//bold
      printer.addText(formatItemLineKitchen(item.textNote, '', 2,'*'));
      printer.addTextStyle(false, false, false, undefined);//text normal
    }
    
    
    if(!(item.customizations && item.customizations.length != 0 ) || item.message) {
        printer.addText("-----------------------------------------------\n");
    }
  });
  
  
  printer.addText("\n \n \n");

  printer.addCut(printer.CUT_FEED);
  printer.send();
 
};

export const prePrintEpson = async(printer: any, ePosDev: any, data: any,isDisconnect?: any) => {
  // let TransactionPaymentEntries = data.TransactionPaymentEntries||[]
  try {
    if (!printer) {
      popupMessage({icon:'info',title: 'Failed!!', description: 'Failed to connect to printer'});
      return;
    }
  
    // const paperWidth = 576; // Chiều rộng của giấy in, có thể thay đổi theo máy in của bạn
    // const logoWidth = 256; // Chiều rộng của logo, điều chỉnh theo kích thước logo thực tế của bạn
    // const logoHeight = 256; // Chiều cao của logo
  
    // Tính toán vị trí x để canh giữa logo
    // const xPosition = (paperWidth - logoWidth) / 2;
    // const imageUrl = '/public/media/images/logo_sp_trungnguyenlegend.png'; // URL của hình ảnh
    // const base64Image = logoBase64//await convertImageToBase64(imageUrl);
  
    // var canvas = document.getElementById('canvas') as any
    // var context = canvas.getContext('2d');
    // context.drawImage(document.querySelector('app-sidebar-logo-default'), 0, 0, 200, 70);
  
  
    // console.log('base64Image',base64Image)
    printer.addLayout(printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 15, 15);
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addTextSmooth(true);
    // printer.addImage(context, xPosition, 0, logoWidth, logoHeight);
    // printer.addTextSize(2,2)
    // printer.addTextStyle(false, false, true, undefined);//bold
    // printer.addTextLineSpace(50)
    // printer.addText(data.store?.storename + '\n');
    // printer.addTextSize(1,1)
    // printer.addTextStyle(false, false, false, undefined);//text normal
  
    // printer.addTextLineSpace(30)
    // printer.addTextDouble(false, false);
    // printer.addText(`Địa chỉ: ` + data.store?.storename+ '\n');
    // printer.addText('\n');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addTextSize(2,2)
    printer.addText(data.name + '\n');
    printer.addTextSize(1,1)
    printer.addTextDouble(false, false);
    printer.addText(`(Pre-Print)` + '\n');
    printer.addTextStyle(false, false, false, undefined);//text normal
  
    printer.addTextLineSpace(10)
  
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Order: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.code + '\t');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Ngày: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    let date = new Date().toLocaleString('en-GB', { hour12: false })//data.transactiondate+' '+data.transactiontime
    printer.addText(date + '\n');
    // printer.addTextStyle(false, false, true, undefined);//bold
    // printer.addText('SL khách: ');
    // printer.addTextStyle(false, false, false, undefined);//text normal
    // printer.addText(`${data.noofcovers?data.noofcovers:''}` + '\t');
    printer.addTextStyle(false, false, true, undefined);//bold
    
    // printer.addText('Bàn: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.sectionname + ' - ');
    printer.addText(data.tablename + '\t');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Phục vụ: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.nameonreceipt + '\n');
  
    
    let {dataInfo} = data
    if(dataInfo) {
      if(dataInfo.title) {
        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addTextStyle(false, false, true, undefined);//bold
        printer.addText(`**${dataInfo.title}**\n`);
        printer.addTextStyle(false, false, false, undefined);//text normal
        if(dataInfo.description) {
          printer.addText(dataInfo.description+'\n');
        }
        if(dataInfo.staffInfo) {
          let note = `Bởi ${dataInfo.staffInfo.code}`
          if(note) {
            printer.addTextStyle(false, false, false, undefined);//bold
            printer.addText(`(${note})\n`);
            printer.addTextStyle(false, false, false, undefined);//text normal
          }
        }
      }
    }
    printer.addTextAlign(printer.ALIGN_LEFT);
    // Bảng tiêu đề
    printer.addText("+---------------------------------------------+\n");
    printer.addText("| ");
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText("Món");
    printer.addText("                                      SL ");
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText("|\n");
  
    printer.addText("+---------------------------------------------+\n");
    
    let items  = data?.HospTransactionLines||data.HospTransactionLines
    items.forEach((item: any,index: number) => {
      let itemname = item.description||item.itemname
      let prefix = item.prefix
      if(item.prefix) {
        if( item.prefix == 'GỘP BÀN' || item.prefix == 'CHUYỂN BÀN') {
          prefix = 'CHUYỂN BÀN'
        }
        itemname = `*${prefix}* ${itemname}`
      }
      const itemLines = splitItemName(`${itemname}`, 37);
      itemLines.forEach((line: any, index: number) => {
        if (index === 0) {
          formatItemLineKitchenPrinter(printer,prefix, line, item.quantity.toString())
          
          // printer.addText(formatItemLineKitchen(line, item.quantity.toString()));
        } else {
          printer.addText(formatItemLineKitchen(line, '',));
        }
      });
      
      // printer.addText("+---------------------------------------------+\n");
      if(item.customizations && item.customizations.length != 0) {
        let toppings = item.customizations
        toppings.forEach((i: any,index: number) => {
          const itemLinesTopping = splitItemName(`${i.description}`, 37);
          itemLinesTopping.forEach((line: any, index: number) => {
            if (index === 0) {
              printer.addText(formatItemLineKitchen(line, i.quantity.toString(), 2));
            } else {
              printer.addText(formatItemLineKitchen(line, '', 2));
            }
          });
        });
        if(!item.message) {
          printer.addText("-----------------------------------------------\n");
        }
  
      }
      if(item.message) {
        printer.addText(formatItemLineKitchen(item.message, '', 2,'*'));
  
      }
      if(!(item.customizations && item.customizations.length != 0 ) || item.message) {
          printer.addText("-----------------------------------------------\n");
      }
    });
    printer.addText("\n");
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Quý Khách cần xuất hóa đơn VAT vui lòng \n');
    printer.addText('thông báo trước khi thanh toán');
    printer.addText("\n \n \n");
  
    printer.addCut(printer.CUT_FEED);
    
    printer.send();
    
  }  catch (error: any) {
    // console.error('Lỗi khi in:', error, error.message);
    // console.log('Lỗi khi in:', error, error.message);
    // Thêm xử lý lỗi nếu cần, ví dụ: hiển thị thông báo cho người dùng
}
  
  
};
const leng = (text: string) => {
  let line = 47
  return line - text.length
}
const formatTextLeftRight = (printer: any, textLeft: string, textRight: string) => {
  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText(textLeft);
  printer.addTextStyle(false, false, false, printer.COLOR_1);//text normal
  printer.addTextAlign(printer.ALIGN_RIGHT);
  printer.addText(`${padText(textRight,leng(textLeft),'right')} \n`);
}
const padText = (text: string, length: number, align: 'left' | 'center' | 'right' = 'left'): string => {
  if (align === 'left') {
    return text.padEnd(length);
  } else if (align === 'right') {
    return text.padStart(length);
  } else {
    const padding = length - text.length;
    const padLeft = Math.floor(padding / 2);
    const padRight = Math.ceil(padding / 2);
    return ' '.repeat(padLeft) + text + ' '.repeat(padRight);
  }
};
const splitItemName = (name: string, maxLength: number,customLeng?: any) => {
  const lines: string[] = [];
  let leng = 0
  if(customLeng) {
    leng = customLeng
  }
  let startIndex = 0;
  while (startIndex < name.length) {
    lines.push(name.substring(startIndex, startIndex + maxLength).padEnd(maxLength, ' '));
    startIndex += maxLength;
  }
  return lines;
};
const formatItemLine = (name: string, quantity: string, price: string, total: string) => {
  const formattedQuantity = quantity.padStart(2, ' ');
  const formattedPrice = price.padStart(7, ' ');
  const formattedTotal = total.padStart(10, ' ');
  return `| ${name.padEnd(15, ' ')} | ${formattedQuantity} | ${formattedPrice} | ${formattedTotal} |\n`;
};
const formatItemLineKitchen = (name: string, quantity: string,ps?: any,symbol?: any) => {
  let padDf = 39
  let a = ''
  if(ps) {
    padDf = padDf - ps - 4
    for(let i = 1; i<ps; i++) {
      a += ' '
    }
    if(symbol) {
      a += symbol
    }
    else {
      a += '+'
    }
  }
  const formattedQuantity = quantity.padStart(2, ' ');
  
  
  return `  ${a}${name.padEnd(padDf, ' ')}  ${formattedQuantity}  \n`;
};
const formatItemLineKitchenPrinter = (printer: any,prefix: string, name: string, quantity: string,ps?: any,symbol?: any) => {
  let padDf = 39
  let a = ''
  if(ps) {
    padDf = padDf - ps - 4
    for(let i = 1; i<ps; i++) {
      a += ' '
    }
    if(symbol) {
      a += symbol
    }
    else {
      a += '+'
    }
  }
  const formattedQuantity = quantity.padStart(2, ' ');
  
  if(prefix) {
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText(`  ${a}*${prefix}*`);
    printer.addTextStyle(false, false, false, undefined);//text normal
    let leng = prefix.length+2
    name = name.substring(leng)
    printer.addText(`${name.padEnd(padDf - leng, ' ')}  ${formattedQuantity}  \n`);
  }
  else {
    printer.addText(`  ${a}${name.padEnd(padDf, ' ')}  ${formattedQuantity}  \n`);

  }
  
  // return `  ${a}${name.padEnd(padDf, ' ')}  ${formattedQuantity}  \n`;
};