/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { ContentHeader } from '../../../theme/layout/components/content'
import { useAuth } from '../../modules/auth'
import { LocalData } from '../../modules/api/localData'
import { useAdvanceForme, useOrderForme, usePaymentForme } from '../../modules/api/requests'
import clsx from 'clsx'
import { sortWithDate } from '../../../theme/helpers'
import { DivRoot, InputSelect, KTFormItem, SearchTable } from '../../../theme/partials'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

export function DashboardWrapper() {
  const [printerIPAddress, setPrinterIPAddress] = useState("192.168.1.16");
  const [printerPort, setPrinterPort] = useState("80");
  const [textToPrint, setTextToPrint] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("");

  const ePosDevice = useRef();
  const printer = useRef();

  const STATUS_CONNECTED = "Connected";

  const print = (text: any,data: any) => {
    let prn = printer.current as any
    console.log('prn',prn)
    if (!prn) {
      alert("Not connected to printer");
      return;
    }

    prn.addLayout(prn.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
    prn.addTextAlign(prn.ALIGN_CENTER);
    prn.addTextSmooth(true);
    prn.addText('\n');
    prn.addText('\n');

    prn.addTextDouble(true, true);
    prn.addText(`CompanyName` + '\n');

    prn.addTextDouble(false, false);
    prn.addText(`CompanyHeader` + '\n');
    prn.addText('\n');

    prn.addTextAlign(prn.ALIGN_LEFT);
    prn.addText('DATE: ' + `currentDate` + '\t\t');

    prn.addTextAlign(prn.ALIGN_RIGHT);
    prn.addText('TIME: ' + `currentTime` + '\n');

    prn.addTextAlign(prn.ALIGN_LEFT);

    prn.addTextAlign(prn.ALIGN_RIGHT);
    prn.addText('REGISTER: ' + `RegisterName` + '\n');
    prn.addTextAlign(prn.ALIGN_LEFT);

    prn.addText('SALE # ' + `SaleNumber` + '\n');

    prn.addTextAlign(prn.ALIGN_CENTER);
    prn.addTextStyle(false, false, true, prn.COLOR_1);
    prn.addTextStyle(false, false, false, prn.COLOR_1);
    prn.addTextDouble(false, true);
    prn.addText('* SALE RECEIPT *\n');
    prn.addTextDouble(false, false);
    prn.addCut(prn.CUT_FEED);
    data?.HospTransactionLines?.forEach((item: any,index: number) => {
      prn.addText(`${index+1}. ${item.itemname} ${item.price} # \n`);
      if(item.customizations) {
        item.customizations.forEach((i: any) =>{
          prn.addText(` ${item.description} ${item.quantity} (${item.price}) # \n`);
        })
      }

    })
    prn.send();
  };


  const [dataDefault, setDataDefault] = useState<any>(null)
  const [tableData, setTableData] = useState<Array<any>>([])  
  const [pageOption, setPageOption] = useState({ page: 1, limit: 50 })
  const [tempRecord, setTempRecord] = useState<any>({})
  const [filter, setFilter] = useState<any>(null)
  const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()

  const {auth} = useAuth()
  const {user} = auth || {}
  // const { data: dataQuery, isSuccess, isFetching } = useOrderForme('?expand=requestorderlines,users&isincludeapprovalhistories=true')
  // const { data: dataAdvances, isSuccess: isSuccessAdvances } = useAdvanceForme('?expand=employeeadvancerequestlines,users&isincludeapprovalhistories=true')
  // const { data: dataPayments, isSuccess: isSuccessPayments } = usePaymentForme('?expand=paymentrequestlines,users&isincludeapprovalhistories=true')
  var address = 'http://192.168.1.16/cgi-bin/epos/service.cgi?devid=local_printer&time?out=60000';

  const optionStatusLocal = Object.values(LocalData.statusData)
  
  const optionsType = [
    {
      label: 'Tất cả',
      value: 'all'
    },
    {
      label: 'Phiếu đề xuất',
      value: '500002'
    },
    {
      label: 'Phiếu tạm ứng',
      value: '500003'
    },
    {
      label: 'Phiếu thanh toán',
      value: '500004'
    },
  ]
  
  
  useEffect(() => {
    if(dataDefault) {
        setTableData(dataDefault.map((item:any)=>item))
    }
  }, [dataDefault])
  useEffect(() => {
    setValue('type', 'all')
  }, [])
  const onSubmit = () => {

  }
  const onSubmitFilter:any = (data: any) => {
    // if(!filter) return data
    let tempData = data.map((item:any)=> item)
    if(watch('type') && watch('type') != 'all' ) {
        tempData = tempData.filter((i:any)=> i.typeCode == watch('type'))
    }
    return tempData
}
  return (
    <>
      <SearchTable  data={dataDefault} setTableData={setTableData} className='search-fix-header' keySearch={['creater','code','title','fullname']} />
      <ContentHeader title={''} />
      <div className={`card mb-6`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark'>Chào {user?.fullname}</span>
          </h3>
          <div className='card-toolbar'></div>
        </div>
        <div className='card-body pt-5'>
          {/* <div className="d-flex flex-wrap">
            {optionStatusLocal.map((item:any,index:number)=> {
                const count = tableData.filter((i:any)=>i.statusLocal == item.value).length
                return <div onClick={e=>print('aaa',[])} key={index} className={clsx(`border border-${item.color} border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg-${item.badge}`)}>
                <div className="d-flex align-items-center">
                  <div className={clsx(`fs-2 fw-bolder counted text-${item.color}`)} data-kt-countup="true" data-kt-countup-value={count} data-kt-countup-prefix="">{count}</div>
                </div>
                <div className={clsx(`fw-bold fs-6 text-${item.color}`)}>{item.label}</div>
              </div>
            })}
          </div> */}
        </div>
      </div>
    
    </>
  )
}
