import clsx from 'clsx';
import { FC } from 'react';
import { formatCurrent, removeAccents } from '../../../helpers';

interface Props {
    value: number | null
    onChange: (value: number | null) => void
    className?: string
    disabled?: boolean
    readOnly?: boolean
}

export const InputPrice: FC<Props> = (props) => {
    const { className, onChange, value, disabled, readOnly } = props

    function renderValue() {
        const inputvalue = value ? formatCurrent(value) : ''
        return inputvalue
    }

    return <input
        value={renderValue()}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(event) => {
            let valuechange = event.target.value ? parseInt(removeAccents(event.target.value)) : null
            onChange(valuechange)
        }}
        className={clsx("form-control form-control-sm", className)}
    />
} 