import { create } from "zustand"
import { getMenubyUser } from "./componentMenu"

export interface SideBarMenuItem {
    title: string
    path: string
    permission: string
    icon?: string
    children?: SideBarMenuItem[]
}

export const useStore = create((set) => ({
  menu: null,
  isSuccess: false,
  setStore: (menu:any) => set((state:any) => {
    return { menu: menu}
  }),
  fetchMenu: async (userID: any) => {
    // const menu:any = await getMenubyUser(userID);
    // await set({ menu: menu, isSuccess: true });
  },
}))
const DATA_MODE = 'dataMode'
export const useStoreMode = create((set) => {
  let dataMode = localStorage.getItem(DATA_MODE)
  return {
    info: dataMode ? JSON.parse(dataMode) : null,
    setMode: (data:any) => set((state:any) => {
      localStorage.setItem(DATA_MODE,JSON.stringify(data))
      return { info: data}
    }),
  }
})
export const useStorePrint = create((set) => ({
  PrinterList: [],
  setPrinterlist: (data:any) => set((state:any) => {
    return { PrinterList: data}
  }),
}))