/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { useTranslation } from 'react-i18next'
import { KTSVG } from '../../widgets'

const HeaderUserMenu: FC = () => {
  const { t } = useTranslation()
  const { currentUser, logout } = useAuth() as any

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-250px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='w-35px h-35px bg-gray-200 me-5 rounded'>
            {currentUser?.avatar
              ? <img alt='avatar' src={currentUser?.avatar} className='w-100 h-100 rounded' style={{ objectFit: 'cover' }} />
              : <div className='d-flex flex-center h-100'>
                <KTSVG path='/media/icons/custom/user2.svg' className='svg-icon-2x text-dark' />
              </div>}
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.fullname}
            </div>
            <span className='text-muted fs-7'>
              {currentUser?.authenticationemail}
            </span>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/account/profile'} className='menu-link px-5 fw-bold'>
          {t('my-profile')}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {t('logout')}
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }

