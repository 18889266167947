import clsx from "clsx"
import { FC, ReactNode } from "react"

interface IStyle {
    isBLockLabel?: boolean
    isHeight?: boolean
    className?: string
    labelWidth?: 'auto' | string
    isRequired?: boolean
    labelClassName?: string
}

interface IKTFormItem extends IStyle {
    title: string | ReactNode
    children?: string | ReactNode
}

export const KTFormItem: FC<IKTFormItem> = ({ title, children, isBLockLabel, className, isHeight, labelWidth, isRequired, labelClassName }) => {
    const labelWidthCus = labelWidth == 'auto' ? labelWidth : (labelWidth ? labelWidth : '110') + 'px'
    const style = !isHeight && !isBLockLabel ? { minHeight: '36.5px', maxHeight: '40px' } : undefined

    return <div
        style={style}
        className={clsx(
            `fv-row align-items-${isHeight ? 'start' : 'center'}`,
            !isBLockLabel && 'd-flex',
            className != undefined ? className : 'mb-4'
        )}>
        <label
            className={clsx(
                labelClassName,
                "form-label mb-0 me-6 text-black fw-normal",
                isBLockLabel ? 'me-auto text-nowrap pe-2' : '',
                isRequired && 'required'
            )}
            
            style={{
                minWidth: !isBLockLabel ? labelWidthCus : 'auto',
                maxWidth: !isBLockLabel ? labelWidthCus : '100%'
            }}>
            {title}
        </label>
        {children}
    </div>
}