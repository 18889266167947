export const LocalData: any = {
    statusData: {
        284210000: { value: "284210000", label: "Mới", badge: 'light-primary', color: 'primary' },
        284210001: { value: "284210001", label: "Chờ phê duyệt", badge: 'light-warning', color: 'warning' },
        284210002: { value: "284210002", label: "Đã phê duyệt", badge: 'light-success', color: 'success' },
        284210003: { value: "284210003", label: 'Đã từ chối', badge: 'light-danger', color: 'danger' },
        284210004: { value: "284210004", label: 'Đã đóng', badge: 'light-danger', color: 'danger' },
        284210005: { value: "284210005", label: 'Cần phê duyệt', badge: 'light-info', color: 'info' },
        284210006: { value: "284210006", label: 'Đã chuyển tiếp', badge: 'secondary', color: 'black' },
    },
    venderType: {
        284210000: { value: "284210000", label: "Nhà cung cấp", badge: 'light-primary' },
        284210001: { value: "284210001", label: "Nhân viên", badge: 'light-info' },
    },
    requestType: {
        284210000: { value: "284210000", label: "Item", badge: 'light-primary' },
        // 284210001: { value: "284210001", label: "Cost Accounting", badge: 'light-info' },
    },
    paymentMethod: {
        284210000: { value: "284210000", label: "Tiền mặt", badge: 'light-primary' },
        284210001: { value: "284210001", label: "Chuyển khoản", badge: 'light-info' },
    },
    approvalType: {
        284210000: { value: "284210000", label: "Đề xuất", badge: 'light-primary' },
        284210001: { value: "284210001", label: "Tạm ứng", badge: 'light-primary' },
        284210002: { value: "284210002", label: "Thanh toán", badge: 'light-primary' },
    }
}


export const localSetings = {
    optionDocumemntType: [
        { value: "word", label: 'Word' },
        { value: "excel", label: 'Excel' },
        { value: "pdf", label: 'PDF' },
        { value: "image", label: 'Image' },
        { value: "text", label: 'Text' },
    ],
}

