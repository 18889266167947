import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import './theme/assets/sass/style.react.scss'
import './theme/assets/sass/style.scss'
import './theme/assets/sass/plugins.scss'
import './i18n'
import { AppRoutes } from './app/router/AppRoutes'
import { AuthProvider, setupAxios, useAuth } from './app/modules/auth'
import { SiteProvider } from './theme/SiteProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
})

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SiteProvider>
            <ToastContainer />
            <AppRoutes />
        </SiteProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
