import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { getLocalStorage, setLocalStorage } from './helpers'

interface Props {
  sidebarMini: boolean
  setSidebarMini: React.Dispatch<React.SetStateAction<boolean>>
}

const SiteContext = createContext<Props>({
  sidebarMini: false,
  setSidebarMini: () => { },
})

interface ISiteConfig {
  selectedLang: string
  sidebarMini: boolean
}

const SiteProvider = ({ children }: { children: ReactNode }) => {
  const configsData = getLocalStorage<ISiteConfig>('site-config')
  const [sidebarMini, setSidebarMini] = useState(configsData?.sidebarMini || false)

  useEffect(() => {
    setLocalStorage('site-config', { ...configsData, sidebarMini: sidebarMini })
  }, [sidebarMini])

  return <SiteContext.Provider value={{ sidebarMini, setSidebarMini }}>
    {children}
  </SiteContext.Provider>
}

const useSiteContext = () => useContext(SiteContext)

export { SiteProvider, useSiteContext }

