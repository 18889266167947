import { MenuItem } from './MenuItem'

export function MenuInner() {
  
  return (
    <span className='wrap-logo-header'>
      <MenuItem className='9hx' title='' to='/' icon='/media/images/logos/logo-dark.svg' />
    </span>
  )
}
