import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nextHttpBackend from 'i18next-http-backend';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(i18nextHttpBackend)
  .use(i18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug: true,
    fallbackLng: "vi",
    returnEmptyString: false
  });

export default i18n;
