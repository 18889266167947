import clsx from "clsx";
import { FC, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { DivRoot } from "../../../partials";
import { useLayout } from "../../core";

interface IItem {
    title: string
    path: string
}

interface IContentHeader {
    title: string
    items?: IItem[]
    elements?: ReactNode
    linkBack?: any
}

export const ContentHeader: FC<IContentHeader> = ({ title, items, elements, linkBack }) => {
    const { pathname } = useLocation()
    const { config } = useLayout()
    const appPageTitleDirection = config.app?.pageTitle?.direction
    const isSettings = pathname.indexOf('system-settings') != -1

    function getTitle(key: string) {
        const isInfo = key.indexOf('info_name') != -1
        if (key.split(" ").length > 1) return key
        let tempKey = key
        if (isInfo) {
            tempKey = tempKey.replace('info_name.', '')
        }
        if (isSettings && tempKey != 'system.setting') {
            tempKey = `system.setting.${tempKey}`
        }
        // if (isInfo) {
        //     return intl.formatMessage({ id: 'info_name' }, { name: intl.formatMessage({ id: tempKey }).toLowerCase() })
        // }
        return tempKey//intl.formatMessage({ id: tempKey })
    }

    return <div className="d-flex flex-stack py-2">
        <div>
            {config.app?.pageTitle?.display && title && (
                <h1
                    className={clsx('page-heading d-flex text-dark fw-bold fs-3 my-0', {
                        ' justify-content-center': appPageTitleDirection,
                        'align-items-center': !appPageTitleDirection,
                    })}
                >
                    {
                        linkBack && 
                        <Link to={linkBack} className="text-muted">
                            <i className="me-3 fs-2 fw-bold text-black bi bi-arrow-left"></i>
                        </Link>
                    }
                    
                    {getTitle(title)}
                </h1>
            )}
            {items && items.length != 0
                ? <div className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 text-muted">
                    {/* <li className="breadcrumb-item">
                        <Link to={'/'} className="text-muted">
                            {intl.formatMessage({ id: "logo.title" })
                            }</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className='bullet bg-muted w-5px h-2px'></span>
                    </li> */}
                    {items.map((item, index) => <DivRoot key={index}>
                        <li className="breadcrumb-item">
                            <Link to={item.path} className="text-muted">
                                {getTitle(item.title)}
                            </Link>
                        </li>
                        {((index + 1) != items.length) && <li className="breadcrumb-item">
                            <span className='bullet bg-muted w-5px h-2px'></span>
                        </li>}
                    </DivRoot>)}
                    <li className="breadcrumb-item">
                        <span className='bullet bg-muted w-5px h-2px'></span>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="text-muted">
                            {getTitle(title)}
                        </span>
                    </li>
                    
                </div>
                : <></>}
        </div>
        <div className="d-flex gap-2">{elements}</div>
    </div>
}
