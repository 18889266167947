import { FC } from 'react';
import ReactSelect, { GroupBase, OptionsOrGroups, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

export interface PropsIOption {
  value: any
  label: string
}

export interface PropsSelect {
  isCreatable?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  disabled?: boolean
  isMulti?: boolean
  className?: string
  options?: OptionsOrGroups<any, GroupBase<any>> | undefined
  value?: any
  defaultValue?: any
  onChange?: (e: { value: string | number, label: string } | any) => void
  placeholder?: string
  size?: 'sm' | 'default' | 'lg',
  width?: string
  indicatorSeparator?: boolean
  border?: boolean
  selectIndicator?: boolean
  components?: Partial<SelectComponents<unknown, false, GroupBase<unknown>>> | undefined
  isAbsolute?: boolean
}

export const InputSelect: FC<PropsSelect> = (props) => {
  const inputSize = props.size || 'sm'
  const isSolid = props.className?.length != 0 && props.className?.split(" ").includes("react-select-solid")
  const isError = props.className?.length != 0 && props.className?.split(" ").includes("form-error")

  const colourStyles: StylesConfig<any, false, GroupBase<any>> | undefined = {
    placeholder: (base) => ({
      ...base,
      color: '#9da1b4',
      fontSize: inputSize == 'sm' ? '0.925rem' : '13px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    indicatorSeparator: (style) => ({
      ...style,
      backgroundColor: props.indicatorSeparator ? style.backgroundColor : 'transparent',
      marginBottom: inputSize == 'sm' ? '8px' : style.marginBottom
    }),
    dropdownIndicator: (base: any) => {
      return {
        ...base,
        display: props.border == false || props.selectIndicator == false ? 'none' : 'flex',
        alignItems: 'center',
        padding: inputSize == 'sm' ? '.25rem' : base.padding,
        width: inputSize == 'sm' ? '25.5px' : base.width,
        backgroundColor: '#f9f9f9',
        margin: 0,
        height: '100%',
        borderRadius: '0px 5px 5px 0',
      }
    },
    clearIndicator: (base) => ({
      ...base,
      padding: inputSize == 'sm' ? '.25rem' : base.padding,
      width: inputSize == 'sm' ? '22.5px' : base.width
    }),
    menuList: (base: any) => ({
      ...base,
      padding: '3px 3px 3px 3px'
    }),
    option: (base: any, { isFocused, isSelected, isDisabled }: any) => ({
      ...base,
      fontSize: inputSize == 'sm' ? '10px' : '1.1rem',
      lineHeight: 1.5,
      color: isSelected ? '#fff' : '#5e6278',
      zIndex: 1,
      backgroundColor: isSelected ? '#009ef7' : 'transparent',
      borderRadius: '5px',
      marginBottom: '3px',
      ':hover': {
        backgroundColor: isSelected ? '#009ef7' : '#f6f6f6'
      },
    }),
    control: (base: any, { menuIsOpen, isMulti, isDisabled }) => ({
      ...base,
      opacity: isDisabled && '0.6',
      borderColor: props.border == false ? "transparent" : '',
      border: isSolid || props.border == false ? 'none' : isError ? 'none' : `1px solid #b5b5c3`,
      backgroundColor: isSolid ? '#f5f8fa' : base.backgroundColor,
      minHeight: inputSize == 'sm' ? '30.6px !important' : '38.2px',
      height: inputSize == 'sm' ? 'auto !important' : 'auto',
      minWidth: props.width || base.minWidth,
      boxShadow: '#b5b5c3',

      ':focus': {
        color: '#5e6278',
        backgroundColor: '#ffffff',
        borderColor: '#b5b5c3',
        outline: 0,
        boxShadow: 'inset 0 1px 2px rgb(0 0 0 / 8%), 0 0 0 0.25rem rgb(0 158 247 / 25%)',
      },
      ':hover': {
        borderColor: '#b5b5c3'
      },
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 100,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const noBorder = props.border == false ? {
        height: '100%',
        display: 'flex',
        alignItems: 'end'
      } : {}

      return {
        ...provided,
        ...noBorder,
        opacity,
        transition,
        fontSize: inputSize ? '.925rem' : '1.1rem',
        lineHeight: 1.5,
        color: '#5e6278',
      };
    },
    multiValue: (provided, state) => {
      const color = state.data.color ? state.data.color : '#5e6278'
      return {
        ...provided,
        backgroundColor: state.data.color ? `${state.data.color}26` : provided.backgroundColor,
        color,
      }
    },
    multiValueLabel: (provided, state) => {
      const color = state.data.color ? state.data.color : '#5e6278'
      return {
        ...provided,
        color,
        fontWeight: 500,
        fontSize: inputSize ? '.925rem' : '1.1rem',
      }
    },
    multiValueRemove: (provided, state) => {
      return {
        ...provided,
        ":hover": state.data.color && {
          color: '#fff',
          backgroundColor: state.data.color
        }
      }
    },
  }

  const rest: any = {
    // menuIsOpen: true,

    components: props.components,
    menuShouldScrollIntoView: true,
    closeMenuOnSelect: props.isMulti ? false : true,
    menuPosition: props.isAbsolute ? 'absolute' : 'fixed',
    onMenuOpen: function () {
      setTimeout(() => {
        const selectedEl = document.getElementsByClassName("select__option--is-selected")[0];
        if (selectedEl) {
          selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
        }
      }, 15);
    },
    placeholder: props.placeholder || '',
    isDisabled: props.disabled,
    classNamePrefix: 'select',
    styles: colourStyles,
    className: [props.className, 'w-100'].join(" "),
    ...props
  }

  return props.isClearable
    ? <CreatableSelect {...rest} />
    : <ReactSelect {...rest} />
}
