import clsx from "clsx"
import { ApiService, ApiShowError, formatCurrent, removeAccents, sortArrayByDependency, sortByNumber, unique_arr } from "../../../../theme/helpers"
import { InputPrice, KTButton, KTFormItem, SearchTable } from "../../../../theme/partials"
import { Dropdown, Modal, Offcanvas } from "react-bootstrap"
import { popupDelete, popupLoading, popupMessage, popupMessageHTMl, popupQuestion } from "../../../modules/messages"
import { CanvasViewlogsPrinter, DetailItem, KITCHENSTATUS, MemberInforAndNote, ModalAddGuest, ModalAddMST, ModalAddPromotion, ModalChangeCustomer, ModalChooseTable, ModalMessage, ModalPay, PromotionAndSum, caculPromo, caculatorPriceAmount, funcAddNote, getItemFromMenu, renderItemProduction } from "./templateOrder"
import { SliderSlick } from "./slideOrder"
import { useMenuStructures } from "../../../modules/api/transactions"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useStoreMode, useStorePrint } from "../../../modules/auth/core/store"
import { STATUSTABLE } from "../../tableService/tabs/constant"
import { callLockTable, cancelOrderInModal, converErrors, formatReturnKitchenStatus, funcSend, getDataTrans, searchWithKeyword, updateOnERP } from "./functionOrder"
import { checkPaid } from "./activeOrder"
import { getAuth, useAuth } from "../../../modules/auth"
import localforage from "localforage"
import { useAsyncDebounce } from "react-table"
import { useTranslation } from "react-i18next"
import { connectPrinterWithTimeout, findAndPrint, isConnectPrinter, prePrintReceipt } from "../../mode/print"
import { getMenuData } from "../../mode/authMode"
export const arrNote = [
    { name: "Ít đường" }, 
    { name: "Ít sữa" },
    { name: "Ít đá" },
    { name: "Ít cà phê" },
    { name: "Không hành" },
    { name: "Pha sẵn" }, 
    { name: "Mang về" },
    { name: "Sữa riêng" },
    { name: "Ngọt" },
    { name: "Lên sau" },
    { name: "Rau trụng" },
    { name: "Trứng chín" },
    { name: "Ít cơm" },
    { name: "Ít bánh" },
    { name: "Tất cả" },
    { name: "Trả phiếu" },
]
export const ModalAddEditOrder = (props: any) => {
    const { t } = useTranslation()
    const {isCallLock,setIsCallLock, show, setShow, dataModal,funcCallback,infoTable, setDataModal, refetchCurrent, refetchTable, refetchCurrentKOT, setDataActive, createHospTrans} = props
    // const { data: dataMenuStructures, isSuccess, isFetched } = useMenuStructures()
    const [dataMenuStructures,setDataMenuStructures] = useState<any>({})
   
    const [dataMenus,setDataMenus] = useState<any>([])
    const [itemLines,setItemLines] = useState<any>([])
    const [mobileTransBenefitLine,setMobileTransBenefitLine] = useState<any>([])
    const [menus,setMenus] = useState<any>([])
    const [groups,setGroups] = useState<any>([])
    const [dataGroups,setDataGroups] = useState<any>([])
    const [items,setItems] = useState<any>([])
    const [toppingItems,setToppingItems] = useState<any>({})
    // const [itemCoupon,setItemCoupon] = useState<any>({})
    
    const [isloading,setIsloading] = useState<any>(false)
    
    const [xQuanlity,setXQuanlity] = useState<any>(1)
    const xTags = [1,2,4,6,8,10]
    const [note,setNote] = useState<any>('')
    // const [showDetailItems,setShowDetailItems] = useState<any>(null)
    const [indexEdit,setIndexEdit] = useState(-1)
    const [menuActive,setMenuActive] = useState<any>(null)
    const [groupActive,setGroupActive] = useState<any>(null)
    const [tableInfo,setTableInfo] = useState<any>(null)
    const [selected, setSelected] = useState<any>([])
    const [merchantsData, setMerchantsData] = useState<any>([])
    const { currentUser } = useAuth() as any
    const [allowpopupcustomization,setAllowpopupcustomization] = useState<any>(false)
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}
    let storeMode = useStoreMode((e: any)=> e.info)
    // console.log('staffPermissionGroupInfo',staffPermissionGroupInfo)
    let storePrint = useStorePrint((e: any)=> e)||{}
    const [user,setUser] = useState<any>({})
    useEffect(()=> {
        const getInitalAuth = async() => {
            const dataAuth = await getAuth()||{} as any
            setUser(dataAuth.user||{})
        }
        getInitalAuth()
    },[])
    useEffect(()=>{
        if(currentUser) {
            // console.log('currentUser',currentUser)
            // console.log('allowpopupcustomization',currentUser?.allowpopupcustomization)
            setAllowpopupcustomization(currentUser?.allowpopupcustomization)
        }
    },[currentUser])
    
    useEffect(()=>{
        const getMenu = async() => {
            let menudata = await localforage.getItem('menudata') as any
            if(!menudata) {
                await getMenuData(storeMode)
                menudata = await localforage.getItem('menudata')||{} as any

            }
            setDataMenuStructures(menudata)
        }
        const getPrinter = async() => {
            let merchantsData = await localforage.getItem('merchantsData')||{} as any
            setMerchantsData(merchantsData)
        }
        getMenu()
        getPrinter()
        
    },[])
    
    const funcEvtHoldItem = () => {
        let mouseHoldTimer: any;
        document.querySelectorAll('.itemLineEvt').forEach(function (item) {
            item.addEventListener('mousedown', function (e) {
            //clear any existing timer
            e.preventDefault()
            clearTimeout(mouseHoldTimer);
            
            mouseHoldTimer = setTimeout(function () {
                //create and dispatch a unique custom event for each element
                var holdEvent = new Event('mousehold');
                // console.log('hold item', item);
                item.dispatchEvent(holdEvent);
                // console.log('hold', item);
                // console.log('hold data-lineno', item.getAttribute("data-lineno"));
                
            }, 500); // adjust the duration as needed
        });

        item.addEventListener('mouseup', function (e) {
            //clear the timer when the mouse is released
            clearTimeout(mouseHoldTimer);
        });
        });
    }
    const [amounttendered,setAmounttendered] = useState<any>(0)
    useEffect(()=>{
        // console.log('dataModal useEffect',dataModal)
        if(dataModal && dataModal.islocked) {
            handleCloseModal()
        }
        
        if(dataModal && dataModal.HospTransactionLines) {
            //------------------------------
            if(isfirstlinecreated !== undefined) {
                if(dataModal.HospTransactionLines.length != 0 || dataModal.receiptno) {
                    setisfirstlinecreated(undefined)
                }
                else {
                    setisfirstlinecreated(true)
                }
            }
            
            //------------------------------
            if(!dataModal.kots || (dataModal.kots?.length == 0)) {
                if(!note) {
                setNote(dataModal.message)
            }
            }
            else if(!note) {
                setNote(dataModal.kots[0]?.comments)
            }
            if(dataModal.TransactionPaymentEntries && dataModal.TransactionPaymentEntries.length != 0) {
                setAmounttendered(dataModal.TransactionPaymentEntries.filter((i: any) =>!i.ischangeline).reduce(function (acc: any, obj: any) { return acc + +obj.amounttendered; }, 0))

            }
            setItemLines(sortByNumber(dataModal.HospTransactionLines,'lineno')||[])
            funcEvtHoldItem()
        }
        else {
            setItemLines([])
            setMenuActive(null)
            setIndexEdit(-1)
        }
    },[show,dataModal])
    const [showTable,setShowTable] = useState(false)
    // const [dataTabTable,setDataTabShowTable] = useState<any>([])
    // const [tab,setTab] = useState<any>({})
    // const getTable = async () => {
    //    let {data} = await ApiService.get(`smarthub/hospdiningareasections/merchant/${staffInfo.merchantid}`)
    //    if(data && data.length != 0) {
    //     setDataTabShowTable(data)
    //     setTab(data[0])
    //    }
    // }

    useEffect(()=>{
            if(infoTable) {
                setTableInfo(infoTable)
            }
            else if(!infoTable && show && !dataModal.id) {
            setShowTable(true)
            // getTable()

        }
    },[show])
    const funcCancelTrans = async() => {
        
        let {data} = await ApiService.get(`smarthub/hosptransactions/${dataModal.id}?expand=hosptransactionlines`)
        let dataTempModal = data
        let HospTransactionLines = dataTempModal.HospTransactionLines //await getDataTrans()
        // console.log('dataTempModal',dataTempModal) //id
        // console.log('HospTransactionLines',HospTransactionLines)
        if(dataTempModal && HospTransactionLines && HospTransactionLines.length!=0) {
            let lines = HospTransactionLines.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)//tồn tại line active
            if(lines.length != 0) {
                updateOnERP(dataTempModal,staffInfo)
            }
            else if(lines.length == 0 && dataTempModal.receiptno) {//k có line nào active
                const urlApi = `smarthub/hosptransactions/update2/${dataTempModal.id}`
                ApiService.put(urlApi, {
                    transactionstatus: 'Cancel',
                    "staffcode": staffInfo.code+`(--test)`,
                    "staffreceiptname": staffInfo.fullname+`(--test)`,
                })
            }
        }
        else if(dataTempModal && dataTempModal.receiptno && HospTransactionLines) {//k có line
            const urlApi = `smarthub/hosptransactions/update2/${dataTempModal.id}`
            ApiService.put(urlApi, {
                transactionstatus: 'Cancel',
                "staffcode": staffInfo.code+`(--test2)`,
                "staffreceiptname": staffInfo.fullname+`(--test2)`,
            })
        }
        
    }
    const handleCloseModalNotAction = () => {
        // console.log('bbbbbbbbbbbbbbbbb')
        if(isCallLock) {
            callLockTable({
                transactionid: dataModal.id,
                lockinfo: null,
            },setIsCallLock)
        }
        refetchCurrent && refetchCurrent()
        setDataModal && setDataModal({})
        setItemLines && setItemLines([])
        setSelected([])
        setShowTable(false)
        setShow(false)
        setXQuanlity(1)
    }
    const handleCloseModal = async() => {
        if(isCallLock) {
            callLockTable({
                transactionid: dataModal.id,
                lockinfo: null,
            },setIsCallLock)
        }
        // console.log('dataModal',dataModal)
        // console.log('show',show)
        if(dataModal && dataModal.id) {
            await funcCancelTrans()
        }
        refetchCurrent && refetchCurrent()
        setDataModal && setDataModal({})
        setItemLines && setItemLines([])
        setSelected([])
        setShowTable(false)
        setShow(false)
        setXQuanlity(1)
        // console.log('handleCloseModal')
        
    }
    
    const { register, reset,setValue,resetField,setError, handleSubmit, control, watch, formState: { errors,isSubmitting } } = useForm<any>()
    const addItemLine = (object: any, isCaculate?: any) => {
        let item = JSON.parse(JSON.stringify(object))
        // console.log("object",object)
        console.log("item",item)
        // console.log("itemLines",itemLines)
        let line = itemLines.find((i: any) => i.lineno && (i.lineno == item.lineno))
        if(line) {
            // console.log('line',line)
            if(item.message) {
                line.message = item.message
            }
            delete line.quantity
            delete line.price
            item = Object.assign(item,line)
        }
        // console.log("after item",item)

        // delete item.Customization
        // console.log('toppingItems.Customization.CustomizationLines',toppingItems.Customization?.CustomizationLines)
        // console.log('toppingItems',toppingItems)
        // console.log('item',item)
        // console.log('quantity_topping',watch('quantity_topping'))
        let customizations = [] as any
        let vat = item.vat || 0

        watch('quantity_topping')?.forEach((i: any, index: number) => {
            let itemline = toppingItems.Customization?.CustomizationLines[index]
            let lineCus = toppingItems.customizations?.find((i: any) => i.id == itemline.id)||{}
            // console.log('lineCus',lineCus)
            if(i && i.value && itemline) {
                let temp = {
                    id: itemline.id,
                    lineno: lineCus.lineno,
                    "statuscode": 1,
                    "entrytype": "Item",
                    "itemno": itemline.hospitemcode,
                    // "itemname": itemline.name,
                    "barcodeno": null,
                    "variantcode": itemline.hospitemvariantcode,
                    "description": itemline.hospitemname,
                    "unitofmeasure": itemline.unitofmeasure,
                    // "terminalno": null,
                    "categorycode": "TOPPING",
                    "subcategorycode": "TOPPBEVERAGE",
                    "linewasdiscounted": false,
                    "price": itemline.price,
                    "quantity": i.value,
                    // "amount": +itemline.price*i.value,
                    // "netprice": +itemline.price*i.value,
                    "discount": lineCus.discount || itemline.discount||0,
                    "discountamount": lineCus.discountamount || itemline.discountamount||0,
                    // "netamount": netamount,
                    "vat": lineCus.vat || itemline.vat||vat,
                    "vatamount": lineCus.vatamount || itemline.vatamount|| 0,
                    // "couponqtyused": 0,
                    "coupondiscountpercent": lineCus.coupondiscountpercent || itemline.coupondiscountpercent||0,
                    "coupondiscountamount": lineCus.coupondiscountamount || itemline.coupondiscountamount||0
                }
                temp = caculatorPriceAmount(temp)
                // console.log('customizations',temp)
                customizations.push(temp)
            }
        })
        // console.log('itemLines',itemLines,)
        let a = itemLines.map((i: any)=>i)
        let HospItemPrices = item.HospItemPrices?.find((i: any) => !i.hospitemvariantid)||item.HospItemPrices?.find((i: any) => i.hospitemvariantid)||{}
        // console.log('HospItemPrices',HospItemPrices,)
        if(!HospItemPrices && !item.id) {
            popupMessage({icon: 'info',description: t('order-deep.not-found-hospItemPrices')})
            return
        }
        // console.log('xQuanlity',xQuanlity,)
        let quantity = item.quantity||xQuanlity
        // console.log('quantity',quantity)
        
        let temp = {
            ...item,
            HospItem: {
                productionsections: item?.productionsections
            },
            kitchenstatus: item.kitchenstatus != KITCHENSTATUS.notSent? undefined : KITCHENSTATUS.notSent,
            description: item.name||item.description,
            price: HospItemPrices.unitprice?HospItemPrices.unitprice : item.price,
            quantity: quantity,
            // tax: vat,
            // vat: vat,
            "vat": item.vat||vat,
            "tax": item.vat||vat,
            "vatamount": item.vatamount|| 0,
            id: item.id,
            customizations: customizations,
            hosptransactionid: item.hosptransactionid,
            "discount": item.discount||0,
            "discountamount": item.discountamount||0,
            // "couponqtyused": 0,
            "coupondiscountpercent": item.coupondiscountpercent||0,
            "coupondiscountamount": item.coupondiscountamount||0,
            "staffcode": staffInfo.code,
            "staffname": staffInfo.fullname,
            "transactiondate": new Date(),
            "transactiontime": new Date().toLocaleTimeString('en-US',{ hour12: false }),
        }
        temp = caculatorPriceAmount(temp)
        // console.log('itemssssssssssssssss',temp)
        // console.log('item',item)

        // console.log('indexEditindexEdit',indexEdit)
        // let index = a.findIndex((i: any) => i.id == temp.id)
        // console.log('isCaculate',isCaculate)
        // console.log('indexEdit',indexEdit)
        if(indexEdit > -1 && typeof isCaculate == "boolean" ) {
            // console.log('aa')
            a[indexEdit] = temp
        }
        // else if(isRecommentTopping) {
        //     let ind = a.length - 1
        //     a[ind] = temp
        // }
        else {
            // console.log('bb',temp)

            a.push(temp)
        }
        // console.log('axxxx',a)

        // setItemLines(a)
        setIndexEdit(-1)
        addOrEditOrder(a, isCaculate)
    }
    
    
    const getInforTopping = async(item: any,index?: number) => {
        if(item.ispaid) {
            return
        }
        console.log(' default',item)
        // console.log('dataMenuStructures.MenuStructureLines',dataMenuStructures.MenuStructureLines)

        reset({})
        setValue('noteLine',item.message)
        let data = getItemFromMenu(dataMenuStructures.MenuStructureLines, item) as any
        
        // console.log('item',item)
        if(!data) {
            // item.name = item.itemname
            data = item
        }
        if(data) {
            // data.itemname = data.name
        }
        data.description = data.itemname || data.name || data.description
        // /c1cf80c4-9d5a-432a-994d-2a71caa0b851?expand=hospitemvariants,hospitemprices,customizations,customizationlines
        // try {
            // const urlApi = `selforders/hospitems/?itemnos=${item.itemno}&expand=hospitemvariants,hospitemprices,customizations,customizationlines`
            // let { data } = await ApiService.get(urlApi)
            if(data ) {
                let record = data //data[0]
                let id = record.id
                record.itemid = id 
                record.id = item.id
                if(item.kotline) {
                    record.kotline = JSON.parse(JSON.stringify(item.kotline))
                    record.kotline.ismodified = true    
                }
                record.quantitydefault = item.quantity||1
                setValue(`quantity_item`,item.quantity)

                record.Customization?.CustomizationLines?.forEach((i: any,index: number) => {
                    // console.log('i',i)
                    let indTopping = item.customizations?.find((f: any) => f.id == i.id)
                    // console.log('indTopping',indTopping)
                    if(indTopping) {
                        i.quantity = indTopping.quantity
                        setValue(`toppingtype.${index}.value`,indTopping.id)
                        setValue(`quantity_topping.${index}.value`,indTopping.quantity)
                        
                    }
                    else {
                        setValue(`toppingtype.${index}.value`,false)
                        setValue(`quantity_topping.${index}.value`,null)
                        
                    }
                })
                // console.log('watch',watch())
                // console.log('record',record)
                setToppingItems(record)
                index != undefined && setIndexEdit(index as any)
            }
            
            // popupMessage({ icon: 'success', autoClose: true })
        // } catch (error) { ApiShowError(error) }
    }
    const formatDataMenus = (data: any) => {
        // console.log('dataaaaaaaa',data)
        if(data) {
            let a = [] as any
            let b = [] as any
            // [
            //     {
            //         navigationlabel: t("order-deep.note"),
            //         parent: 'note',
            //         hostitems: arrNote?.map((item: any) => {
            //             return {
            //                 name: item.name,
            //                 id: removeAccents(item.name),
            //                 type: 'note',
            //             }
            //         }).concat([{
            //             name: t('orders-hub-deep.free-text'),
            //             id: 'free-text',
            //             type: 'note',
            //         }])
            //     }
            // ] as any
            let c = [] as any
            data?.forEach((item: any) => {
                a = a.concat(item)
                let tempGroups = [] as any
                if(item) {

                }
                if(item.children && item.children.length != 0) {
                    if(item.children[0].menuitemtype == 'Item') {
                        let hostitems = JSON.parse(JSON.stringify(item.children))
                        delete item.children
                        item.children = [
                            {
                                navigationlabel: "",
                                parent: item.menukey,
                                type: 'custom',
                                displayTitle: false,
                                hostitems: hostitems,
                            },
                        ]
                        // tempGroups.push(i)
                    }
                    item.children?.forEach((i: any) => {
                        tempGroups.push(i)
                    })
                    
                }
                else {
                    item.parent = item.menukey
                    tempGroups.push(item)
                }
                b = b.concat(tempGroups)
            });
            // console.log('a',a)
            // console.log('b',b)
            setMenus(a)
            setGroups(b)
            handleTabMenuActive(a[1],b)
            // setItems(c)
            // console.log('c',c)
        }
    }
    useEffect(()=>{
        if(dataMenuStructures && dataMenuStructures.MenuStructureLines) {
            // console.log('dataMenuStructures',dataMenuStructures)
            let b = dataMenuStructures.MenuStructureLines?.map((i: any) => ({...i,parent:i.parent != "0"?i.parent:null}))
            // console.log('b',b)
            let a = sortArrayByDependency(b,'parent','menukey')
            // console.log('aaaaaaaaaaaaaaa',a)
            formatDataMenus(a)
            setDataMenus(a)
        }
    },[dataMenuStructures])
    // const handleNumber = (str: string) =>{
    //     setValuePrice(valuePrice+str)
    // }
    // const handleReplaceNumber = (str: string) =>{
    //     setValuePrice(str)
    // }
    // const handleSplitNumber = () =>{
    //     setValuePrice(valuePrice.slice(0, -1))
    // }
    const applyFromErp = (iData: any, item: any) => {
        iData.discount = +item.DiscountPercent
        iData.discountamount = +item.DiscountAmount
        iData.price = +item.Price
        iData.netprice = +item.NetPrice
        iData.netamount = +item.NetAmount
        iData.couponcode = item.CouponCode
        iData.couponcode = +item.Barcode
        
        
        if(item.ItemDescription) {
            iData.description = item.ItemDescription
        }
      
        if(+item.TAXAmount) {
            iData.vatamount = +item.TAXAmount
        }
        if(+item.NetAmount && +item.TAXAmount) {
            let amount = +item.NetAmount+ +item.TAXAmount
            iData.amount = amount
            iData.vat = ((amount - +item.NetAmount)*100/+item.NetAmount).toFixed(0)
        }
        else {
            iData.amount = +item.Price * +item.Quantity
        }
        if(+item.VATPercent) {
            iData.vat = +item.VATPercent
        }
        return iData
    }
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    const funcCalculateBasket = async(dataModal: any,promoCode?: any) => {
        // console.log(' dataModal',dataModal)
        setIsloading(true)
        let tempCoupon = promoCode||[]
        if(dataModal.coupon) {
            tempCoupon = unique_arr(tempCoupon.concat(dataModal.coupon),'code')
        }
        try {
            const urlApi = `lscentral/hosporders/calculatetransaction`
            let tempData = {
                transactionid: dataModal.id,
                "HospTransDiscountLines": tempCoupon && tempCoupon.length != 0 ? tempCoupon.map((item: any,index: number) =>({
                    "storeno": dataModal.storeno,
                    "linetype": 6,
                    "lineno": index+1,
                    "barcodeno": item.code,
                    "quantity": 1,
                    "entrytype": "Coupon",
                    
                })):undefined,
            }
            let {data} = await ApiService.post(urlApi, tempData)
            setIsloading(false)
            // console.log('data',data)
            let HospTransactionLines = dataModal.HospTransactionLines
            // let ERPLine = [] as any
            if(data && data.SoapBody && data.SoapBody.HospOrderCalculate_Result)  {
                let HospOrderCalculate_Result = data.SoapBody.HospOrderCalculate_Result || {}
                let {errorText,mobileTransactionXML} = HospOrderCalculate_Result
                if(errorText.length != 0) {
                    popupMessage({icon:'error',description: errorText})
                    return
                }
                if(mobileTransactionXML) {
                    let {MobileTransaction, MobileTransactionLine, MobileTransDiscountLine, MobileTransBenefitLine} = mobileTransactionXML
                    if(!Array.isArray(MobileTransDiscountLine) && MobileTransDiscountLine) {
                        MobileTransDiscountLine = [MobileTransDiscountLine]
                    }
                    if(!Array.isArray(MobileTransactionLine) && MobileTransactionLine) {
                        MobileTransactionLine = [MobileTransactionLine]
                    }
                    if(!Array.isArray(MobileTransBenefitLine) && MobileTransBenefitLine) {
                        MobileTransBenefitLine = [MobileTransBenefitLine]
                    }
                    if(MobileTransBenefitLine && MobileTransBenefitLine.length != 0) {
                        setMobileTransBenefitLine(MobileTransBenefitLine)
                    }
                    if(MobileTransDiscountLine && MobileTransDiscountLine.length != 0) {
                        let coupon = MobileTransDiscountLine.filter((i: any) => i.DiscountTypeText == 'Coupon') as any
                        
                        if(coupon && coupon.length != 0) {
                            coupon.forEach((item: any) => {
                                let record = MobileTransactionLine.find((i: any) => i.LineNo == item.LineNo)
                                if(record) {
                                    let indexTranLine = HospTransactionLines.findIndex((i: any) => i.lineno == record.LineNoExt)
                                    if(indexTranLine > -1 && item.DiscountAmount) {
                                        HospTransactionLines[indexTranLine].coupondiscountamount = item.DiscountAmount
                                        HospTransactionLines[indexTranLine].coupondiscountpercent = item.DiscountPercent
                                    }
                                }
                                
                            })
                        }
                        if(MobileTransactionLine && MobileTransactionLine.length != 0) {
                            HospTransactionLines.forEach((item: any) => {
                                // let tempHospTransactionLines = {
                                //     id: HospTransactionLines[indexTranLine].id
                                // } as any
                                if(item.customizations && item.customizations.length != 0) {
                                    item.customizations.forEach((i: any) => {
                                        let tempCus = MobileTransactionLine.find((m: any) => m.LineNoExt == i.lineno)
                                        if(tempCus) {
                                            i = applyFromErp(i,tempCus)
                                        }
                                    })
                                }
                                let tempLine = MobileTransactionLine.find((m: any) => m.LineNoExt == item.lineno)
                                if(tempLine) {
                                    item = applyFromErp(item,tempLine)
                                }
                            })

                        } 
                    }
                    // console.log('ERPLine',ERPLine)
                    // let temp = JSON.parse(JSON.stringify(dataModal))
                    //MobileTransaction
                    let dataAmount = caculPromo(HospTransactionLines,dataModal)
                    let DiscountAmount = +MobileTransaction?.LineDiscount + +MobileTransaction?.ManualTotalDiscAmount + +MobileTransaction?.TotalDiscount
                    let temp = {
                        id: dataModal?.id,
                        coupon: tempCoupon,
                        "netamount": MobileTransaction?.NetAmount,
                        "taxamount": dataAmount.taxamount,
                        "grossamount": MobileTransaction?.GrossAmount,
                        linediscount: +MobileTransaction?.LineDiscount, 
                        // manualtotaldiscount: +MobileTransaction?.ManualTotalDiscAmount, 
                        discountamount: DiscountAmount,
                        "totaldiscount": +MobileTransaction?.TotalDiscount,
                        "discountlines": MobileTransDiscountLine?.map((i: any) => {
                            let item = MobileTransactionLine?.find((l: any) =>l.LineNo == i.LineNo)||{}
                            return {...i,Description: i.DiscountTypeText == 'Coupon'?i.DiscountTypeText : i.Description, LineNoExt: item.LineNoExt, ItemNo: item.Number}
                        }),
                        "HospTransactionLines": HospTransactionLines,
                    }
                    setDataModal({
                        ...dataModal,
                        ...temp
                    })
                    funcUpsertwithdetails(temp)
                }
            }
            
        } catch (error) { 
            setIsloading(false)
            ApiShowError(error,'funcCalculateBasket',staffInfo)
        }
    }
    const funcUpsertwithdetails = async(tempData: any,isCaculate?: any) => {
        // console.log('tempData',tempData)
        // console.log('dataModal',JSON.parse(JSON.stringify(dataModal)))
        // popupMessage({ icon: 'success', autoClose: true })
        if(tempData.isfirstlinecreated) {
            setisfirstlinecreated(undefined)
        }
        let HospTransactionLines = tempData.HospTransactionLines
        let a = HospTransactionLines.filter((i: any) => i.id)
        let b = HospTransactionLines.filter((i: any) => !i.id)
        if(a.length != 0) {
            a = unique_arr(a,'id')
        }
        tempData = {
            ...tempData,
            HospTransactionLines: a.concat(b)
        }
        try {
            let {data} = await ApiService.post(`/smarthub/hosptransactions/upsertwithdetails/`, tempData)
            if(data.errors && data.errors.length != 0) {
                let errors = converErrors(data.errors) as any
                popupMessage({icon: 'info', description: errors.join('; ')})
            }
            if(isCaculate === true) {
                funcCalculateBasket({
                    ...dataModal,
                    ...tempData
                })
            }
            if(data && data.upsertedHospTransaction ) {
                setDataModal && setDataModal((current: any) => {
                    return {
                        ...current,
                        receiptno: data.upsertedHospTransaction.receiptno ? data.upsertedHospTransaction.receiptno : current.receiptno,
                        transactiontime: data.upsertedHospTransaction.transactiontime,
                        HospTransactionLines: current.HospTransactionLines.map((i: any) => {
                            let item = data.upsertedHospTransaction.HospTransactionLines.find((f: any) => f.lineno == i.lineno)
                            if(item) {
                                i.id = item.id
                                i.kitchenstatus = item.kitchenstatus
                            }
                            
                            return i
                        })

                    }
                })
            }
            setIndexEdit(-1)
        } catch (error) { 
            ApiShowError(error,'funcUpsertwithdetails',staffInfo)
        }
        
   
    }
    const onEventCallApi = useAsyncDebounce((value,isCaculate) => funcUpsertwithdetails(value,isCaculate), 0);

    const addOrEditOrder = async(dataRecord: any,isCaculate?: any) =>{
        // console.log('isEdit',edit)
        // console.log('dataRecord',dataRecord)
        // console.log('dataModal',dataModal)
        
        let item = JSON.parse(JSON.stringify(dataModal))||{}
        // console.log('item',item)
        let tempCustomizations = [] as any
        let arrLineno = dataRecord.filter((i: any) => i.lineno).map((o: any) => o.lineno)
        let HospTransactionLines = dataRecord.map((i: any,index: number) => {
            let updateKot = undefined
            let lineno = (index+1)*1000
            if(i.lineno) {
                lineno = i.lineno
            }
            else if(arrLineno.length !=0) {
                let max = Math.max(...arrLineno) 
                lineno = max+1000
            }
            arrLineno.push(lineno)
            // console.log('lineno',lineno)
            if(i.kotline) {
                updateKot = {
                    id: i.kotline.id,
                    lineno: lineno,
                    quantity: i.quantity,
                    ismodified: i.kotline.ismodified,
                    itemno: i.kotline.itemno,
                    isvoided: i.kotline.isvoided,
                }
            }
            let customizations = i.customizations?.map((cus: any,ind: number) =>{
                let linenocus = cus.lineno
                if(!linenocus) {
                    let a = i.customizations.filter((i: any) => i.lineno)
                    let max = null
                    if(a.length != 0) {
                        max = Math.max(...a.map((o: any) => o.lineno))
                    }
                    // console.log('max',max)
                    if(max) {
                        linenocus = max+1
                    }
                    else {
                        linenocus = lineno+ind+1
                    }
                }
                let temp = {...cus,lineno: linenocus}

                return temp
            })||[]
            tempCustomizations = tempCustomizations.concat(customizations)
            // console.log('staffInfo',staffInfo)
            
            let temp = {
                id: i.id,
                "statuscode": 1,
                "hosptransactionid": i.hosptransactionid,
                "hosptransactioncode": i.hosptransactioncode,
                "lineno":lineno,
                // "receiptno": null,
                "entrytype": "Item",
                "itemno": i.itemno || i.code,
                // "itemname": i.itemname || i.name || i.description,
                "barcodeno": i.barcodeno,
                "variantcode": i.variantcode,
                "description": i.description,
                "unitofmeasure": i.unitofmeasure,
                "storeno": i.storeno?i.storeno:staffInfo.Merchant?.storecode,
                // "terminalno": null,
                "categorycode": i.categorycode,
                "subcategorycode": i.subcategorycode,
                "linewasdiscounted": false,
                "price": +i.price,
                "netprice": +i.netprice,
                "quantity": +i.quantity,
                "vat": i.vat,
                "discount": i.discount?i.discount:0,
                // "discountamount": i.discountamount,
                "netamount": i.netamount,
                "vatamount": i.vatamount,
                "amount": +i.amount,
                "transactiondate": i.transactiondate,
                "transactiontime": i.transactiontime,
                // "couponqtyused": 0,
                "coupondiscountpercent": i.coupondiscountpercent||0,
                "coupondiscountamount": i.coupondiscountamount||0,
                "discountamountpercent": i.discountamountpercent||0,
                "discountamount": i.discountamount||0,
                "customizations": customizations,
                kotline: updateKot,
                message: i.message,
                HospItem: i.HospItem,
                kitchenstatus: i.kitchenstatus != KITCHENSTATUS.notSent? undefined : i.kitchenstatus,
                "staffcode": i.staffcode,
                "staffname": i.staffname,
                markedforitembenefit: i.markedforitembenefit,
            }
            
            return temp
        })
        let dataAmount = caculPromo(HospTransactionLines, dataModal)
        // console.log('HospTransactionLines',HospTransactionLines)
        // console.log('item.hospitalitytype',item.hospitalitytype)
       
        let discountAmount = +dataAmount?.linediscount||0 + +dataModal?.totaldiscount||0
        let tempData = {
            id: dataModal?.id,
            code: dataModal?.code,
            "statuscode": 1,
            
            "saletype": item.saletype?item.saletype:(tableInfo?.qrcodeordertype||'TAKEAWAY'),
            "hospitalitytype": item.hospitalitytype?item.hospitalitytype:tableInfo?.salestype,
            "merchantkey": staffInfo.Merchant?.merchantkey,
            "merchantcode": staffInfo.Merchant?.merchantcode,
            "merchantname": staffInfo.Merchant?.merchantname,
            "merchantid": staffInfo.merchantid,
            "storeno": staffInfo.Merchant?.storecode,
            "tableid": item.tableid?item.tableid:tableInfo?.id,
            "tableno": item.tableno?item.tableno:tableInfo?.tableno,
            "tablename": item.tablename?item.tablename:tableInfo?.tablename,
            "sectionid": dataModal.sectionid?dataModal.sectionid:tableInfo?.sectionid,
            "sectioncode": dataModal.sectioncode?dataModal.sectioncode:tableInfo?.sectioncode,
            "sectionname": dataModal.sectionname?dataModal.sectionname:tableInfo?.sectionname,
            "transactiontype": dataModal.transactiontype?dataModal.transactiontype:"Sales",
            "transactionstatus": dataModal.transactionstatus?dataModal.transactionstatus:"Active",
            "kitchenstatus": dataModal.kitchenstatus?dataModal.kitchenstatus:"Not Sent",
           
            "staffcode": staffInfo.code,
            "staffreceiptname": staffInfo.nameonreceipt,
          
            "noofitems": 1,
            "noofitemlines": 1,
            "netamount": dataAmount.netamount,
            "taxamount": dataAmount.taxamount,
            "grossamount": dataAmount.grossamount,
            // manualtotaldiscount: +dataModal?.manualTotalDiscount, //+thêm
            linediscount: +dataAmount?.linediscount, 
            discountamount: discountAmount,
            "totaldiscount": +dataModal?.totaldiscount,//+thêm chương trình tên là totaldiscount
            "customerinfo": dataModal.customerinfo||{name: 'Guest'},
            "HospTransactionLines": unique_arr(HospTransactionLines,'lineno'),
            "isfirstlinecreated": isfirstlinecreated
           
        }
        setValue('quantity_topping',[])
        setValue('noteLine','')
        // console.log('dataModal',item)
        // console.log('itemLines',itemLines)
        // console.log('isfirstlinecreated',isfirstlinecreated)
        onEventCallApi(tempData,isCaculate)
        // const method = 'post'
        // const urlApi = `/smarthub/hosptransactions/upsertwithdetails/`
        // let {data} = await ApiService[method](urlApi, tempData)
        let temp = {
            ...dataModal,
            ...tempData
        } as any
        if(isfirstlinecreated) {
            temp.transactiontime = new Date().toLocaleTimeString('en-US',{ hour12: false })
        }
        setDataModal && setDataModal(temp)
        
        
        setIndexEdit(-1)
    }
    const [dataSentkitchen,setDataSentkitchen] = useState<any>([])
    const [isShowNote,setIsShowNote] = useState(false)
    const [isShowMST,setIsShowMST] = useState(false)
    
    const funcSendOrder = async() => {
        funcSend(dataModal,setDataModal,user, staffInfo, storePrint, handleCloseModalNotAction, note)
        // refetchTable && refetchTable()
        // actionSend()
        return
        let ids = [] as any//checkFilterAction(selected, KITCHENSTATUS.open)
        if(selected.length == 0) {
            ids = itemLines.filter((i: any) => i.kitchenstatus == KITCHENSTATUS.open).map((i: any) => i.id)
        }
        let a = itemLines.filter((i: any) => ids.includes(i.id) && i.HospItem?.productionsections).map((i: any) => i.HospItem?.productionsections)
        
        let tempDataKitchen = [] as any
        let dataKitchen = [] as any
        a.forEach((item: any) => {
            tempDataKitchen = tempDataKitchen.concat(item)
        })
        let b = Array.from(new Set(tempDataKitchen))
        b.forEach((item: any) => {
            let temp = {
                name: item,
                quantity: tempDataKitchen.filter((i: any) => i == item).length
            }
            dataKitchen.push(temp)
        })
        setDataSentkitchen(dataKitchen)
        if(dataKitchen.length != 0) {
            // setModalSend(true)
            setValue('sentKitchen',[])
        }
        // else actionSend()
        // actionSend()
    }
    
    const checkConnectPrinterToSend = async (itemLines: any) => {
        let isCheck = [] as any
        let dataRender = renderItemProduction(unique_arr(itemLines,'lineno'))
        let {productionsections, sectiondispstatmappings} = merchantsData
        let sectionid = dataModal?.sectionid
        let username = (await getAuth() as any).user?.username
        // (async function () {
            for await (const i of dataRender) {
                let code = productionsections?.find((p: any) => p.type == i.name)?.code
                // console.log('code',code)
                if(code) {
                 let printInf = sectiondispstatmappings?.find((s: any) => s.hospdiningareasectionid == sectionid && s.productionsectioncode == code)?.PrinterList
                //  console.log('printInf',printInf)
                 
                 if(printInf) {
                     let tempData = {
                         host: printInf.host,
                         port: printInf.port
                     }
                    await connectPrinterWithTimeout(tempData, 10000)
                    .then((printer) => {
                        console.log('Kết nối thành công đến máy in');
                        isCheck.push({
                            value: true,
                        })
                        // Thực hiện thao tác in
                    })
                    .catch((error) => {
                        isCheck.push({
                            value: false,
                            error: error.message
                        })
                        let tempData = {
                            funcName: 'checkConnectPrinterToSend',
                            error: error.message,
                            staffcode: staffInfo?.code,
                            username: username,
                        }
                        ApiService.post(`smarthub/logs/write`, tempData)
                        // console.log('Lỗi:', error?.message);
                    });
                     
                     
                 }
                 else {
                     popupMessage({description:"Please check config printer!", icon: 'info'})
                     let tempData = {
                        funcName: 'checkConnectPrinterToSend',
                        error: `Please check config printer!`,
                        staffcode: staffInfo?.code,
                        username: username
                    }
                    ApiService.post(`smarthub/logs/write`, tempData)
                 }
                }
                else {
                 popupMessage({description:"Please check setup productionsections item!", icon: 'info'})
                 let tempData = {
                    funcName: 'checkConnectPrinterToSend',
                    error: `Please check setup productionsections item!`,
                    staffcode: staffInfo?.code,
                    username: username
                }
                ApiService.post(`smarthub/logs/write`, tempData)
             }
          
              break; // Closes iterator, triggers return
            }
            let a = isCheck.find((i: any) => i.value === false)
        return a
    }
    
    const actionStay = async() => {
        popupLoading()
        const method = 'put'
        const urlApi = `smarthub/hosptransactionlines/updatemulti/updatekitchenstatus`
        // let ids = checkFilterAction(selected, KITCHENSTATUS.stay)
        let dataTransLine = await getDataTrans(dataModal, setDataModal, user, staffInfo)
        if(dataTransLine === false) {
            return
        }
        let itemsSent = dataTransLine?.filter((i: any) => i.id && i.kitchenstatus == KITCHENSTATUS.notSent && !selected.includes(i.lineno))
        let idsSent = itemsSent.map((i: any) => i.id)
        let tempDataSent = {
            kitchenstatus: KITCHENSTATUS.sent,//dataModal.transactionstatus,
            ids: idsSent,
            "staffcode": staffInfo.code,
            "staffname": staffInfo.fullname,
        }
        if(idsSent.length != 0) {
            let isFailed = await checkConnectPrinterToSend(itemsSent)
            if(process.env.REACT_APP_ACTIONSEND) {
                isFailed = false
            }
            if(isFailed) {
                popupMessage({icon: 'info', title: isFailed.error, description: t('order-deep.failed-to-connect-to-printer')})
                return
            }
            
            let {data} = await ApiService[method](urlApi,tempDataSent)
            let username = (await getAuth() as any).user?.username

            if(!data?.Transactionlines) {
                let tempData = {
                    data: data,
                    funcName: 'Chế biến data trả về (actionStay)',
                    error: 'Errors',
                    staffcode: staffInfo?.code,
                    username: username,
                }
                ApiService.post(`smarthub/logs/write`, tempData)
                popupMessage({icon:'info',description: 'Kiểm tra lại data đồng bộ line', callFunc: handleCloseModalNotAction})

                return
            }
            let tempLines = formatReturnKitchenStatus(JSON.parse(JSON.stringify(dataTransLine)),data||{},idsSent, dataModal.transactionstatus)
            
            let a = data?.Transactionlines?.map((i: any) => i.id) || idsSent
            let b = tempLines.filter((i: any) => a.includes(i.id) )
            setDataModal({...dataModal,HospTransactionLines: tempLines})
            findAndPrint(b,{
                ...dataModal,
                message: note ? note : dataModal.message,
                dataInfo: {
                    staffInfo: staffInfo,
                }
            }, {
                PrinterList: storePrint.PrinterList,
                setPrinterlist: storePrint.setPrinterlist,
                staffInfo: staffInfo,
            })
            if (idsSent.length != 0) {
                updateOnERP(dataModal,staffInfo)
            }
            
        }
        
        popupMessage({ icon: 'success', autoClose: true })
        handleRefeshMenu()
        setSelected([])
    }
    
    const searchFunc: any = (value: string) => {
        let data = [] as any
        dataMenuStructures.MenuStructureLines.forEach((i: any) => {
            if(i.hostitems) {
                data = data.concat(i.hostitems)
            }
        })
        let results = data

            // console.log('results',results)
            // console.log('dataMenuStructures.MenuStructureLines',dataMenuStructures.MenuStructureLines)
            if (value) {
            results = searchWithKeyword(data, value, ['code','name', 'description'])
            // console.log('results',results)
            let tempData= [{
                hostitems: results,
                navigationlabel: value,
                id: removeAccents(value),
            },{
                hostitems: [],
                navigationlabel: 'Clear Search',
                id: 'clear',
            }]
            setSubMenu(removeAccents(value))
            // console.log('tempData',tempData)
            setDataGroups(tempData)
        }
        else {
            handleTabMenuActive(menus[0])
        }
    }
    const handleTabMenuActive = (item: any,arr_groups?: any) => {
        handleRefeshMenu()
        setMenuActive(item.menukey)
        let tempGroup = arr_groups || groups
        let a = tempGroup?.filter((i: any) => i.parent == item.menukey)
        setSubMenu(a[0]?.id)
        setDataGroups(a)
    }
    const handleTabGroupActive = (item: any) => {
        // console.log('item',item)
        setGroupActive(item.menukey)
        
    }
    const onSubmit = (data: any) => {
        let recordTopping = JSON.parse(JSON.stringify(toppingItems))
        let quantitydefault = recordTopping.quantitydefault
        if(recordTopping.kitchenstatus && recordTopping.kitchenstatus != KITCHENSTATUS.notSent) {
            if(staffPermissionGroupInfo.ismanagerprivileges && data.quantity_item != quantitydefault) {
                let textNote = `Chuyển số lượng từ ${quantitydefault} -> ${data.quantity_item}`
                // console.log('textNote',textNote)
                recordTopping.textNote = textNote
                
                findAndPrint([recordTopping],{
                    ...dataModal,
                    message: note?note:dataModal.message,
                    dataInfo: {type:'change',title: 'THAY ĐỔI',staffInfo: staffInfo}

                }, {
                    PrinterList: storePrint.PrinterList,
                    setPrinterlist: storePrint.setPrinterlist,
                    staffInfo: staffInfo,
                },)
            }
            else {
                return
            }
        }
        // console.log('data',data.quantity_item)
        // console.log('recordTopping',recordTopping)
        // console.log('quantitydefault',quantitydefault)
        let isCaculate = false
        if(dataModal?.coupon && dataModal.coupon.length != 0) {
            isCaculate = data.quantity_item != quantitydefault ? true: false
        }
        recordTopping.quantity = data.quantity_item
        recordTopping.message = data.noteLine
        // console.log('isCaculate',isCaculate)

        // console.log('toppingItems',toppingItems)
        // funcCalculateBasket(dataModal)
        setIsShowDetail(false)
        addItemLine(recordTopping,isCaculate)
    }
    const removeItemLine = async() => {
        let aws = true
        let tempDataModal = JSON.parse(JSON.stringify(dataModal))
        let recordBenefit = tempDataModal.HospTransactionLines.find((i: any) => i.markedforitembenefit)
        let textNotify = t('order-deep.confirm-delete-item')
        if(recordBenefit) {
            textNotify = t('order-deep.confirm-delete-item-with-item-benefit')
        }
        if(toppingItems.kitchenstatus && toppingItems.kitchenstatus != KITCHENSTATUS.notSent || recordBenefit) {
            aws = await popupQuestion({description: textNotify})
        }
        if(aws) {
            if(!toppingItems.lineno) {
                setIndexEdit(-1)
            }
            // let a = await popupDelete()
            // if(a) {
                // return
                // setItemLines()
                // console.log('itemLines',itemLines)
                // console.log('toppingItems',toppingItems)
                let lines = tempDataModal.HospTransactionLines?.filter((i:any) => i.lineno != toppingItems.lineno)
                
                setSelected(selected.filter((i:any) => i != toppingItems.lineno))
                setIndexEdit(-1)
                // try {
                    if(recordBenefit) {
                        const method = 'post'
                        const urlApi = `/smarthub/hosptransactionlines/deletebylineno`
                        let temp = {
                            // isvoided: true,
                            lineno: +recordBenefit.lineno,
                            hosptransactionid: dataModal.id,
                            staffcode: staffInfo.code, 
                            staffname: staffInfo.fullname,
                        }
                        ApiService[method](urlApi,temp)
                    }
                    if(toppingItems.kitchenstatus != KITCHENSTATUS.notSent) {
                        // const urlApi = `/smarthub/kotlines/updatebyid/`+toppingItems.kotline?.id
                        const method = 'post'
                        const urlApi = `/smarthub/hosptransactionlines/deletebylineno`
                        let temp = {
                            // isvoided: true,
                            lineno: +toppingItems.lineno,
                            hosptransactionid: dataModal.id,
                            staffcode: staffInfo.code, 
                            staffname: staffInfo.fullname,
                        }
                        let {data} = await ApiService[method](urlApi,temp)
                        let indexLine = itemLines.findIndex((i:any) => i.lineno == toppingItems.lineno)
                        if(indexLine > -1 ) {
                            if(itemLines[indexLine].kotline) {
                                itemLines[indexLine].kotline.isvoided = true
                            }
                            itemLines[indexLine].kitchenstatus = KITCHENSTATUS.voided
                            // itemname = data
                        }
                        tempDataModal.HospTransactionLines = itemLines.filter((i: any) => !i.markedforitembenefit)
                        let items = [itemLines[indexLine]]
                        // console.log('items',items)
                        findAndPrint(items,{
                            ...dataModal,
                            message: note?note:dataModal.message,
                            dataInfo: {type:'voided',title: 'HỦY MÓN',staffInfo: staffInfo}

                        }, {
                            PrinterList: storePrint.PrinterList,
                            setPrinterlist: storePrint.setPrinterlist,
                            staffInfo: staffInfo,
                        },'HỦY MÓN')
                    }
                    else {
                        tempDataModal.HospTransactionLines = lines.filter((i: any) => !i.markedforitembenefit)
                        const method = 'post'
                        const urlApi = `/smarthub/hosptransactionlines/deletebylineno`
                        let temp = {
                            lineno: +toppingItems.lineno,
                            hosptransactionid: dataModal.id,
                            staffcode: staffInfo.code, 
                            staffname: staffInfo.fullname,
                        }
                        let {data} = await ApiService[method](urlApi,temp)
                        if(dataModal?.coupon && dataModal.coupon.length != 0) {
                            funcCalculateBasket({
                                ...tempDataModal,
                            })
                        }
                    }
                    setValue('quantity_topping',[])
                    setDataModal(tempDataModal)
                    
            // }
        }
        
    }

    const templateControlQuanlity = ( field: any,defaultValue: any, isDisabled: any)  => {
        // setValue(field,1)
        let fieldValue = +(watch(field)||defaultValue)
        // console.log('fieldValue',fieldValue)
        return <div className="d-flex gap-2">
        <span className="btn btn-sm btn-secondary btn-icon rounded-5" onClick={e=>{
            if(isDisabled || !staffPermissionGroupInfo.ismanagerprivileges) return
            if(fieldValue > 1) {
                setValue(field,+watch(field) - 1)
            }
            else {
                if(field == 'quantity_item') {
                    if(staffPermissionGroupInfo.isvoiditemsenttokitchen || staffPermissionGroupInfo.isvoidline) {
                        removeItemLine()
                    }
                }
            }
        }}> <i className="bi bi-dash-circle"></i></span>
        <input disabled={isDisabled} {...register(field)} className="form-control form-control-sm w-50px text-center" defaultValue={defaultValue} readOnly type="text" />
        <span className="btn btn-sm btn-secondary btn-icon rounded-5" onClick={e=>{
            if(isDisabled || !staffPermissionGroupInfo.ismanagerprivileges) return
            // console.log('watch(field)',watch(field))
            // console.log('watch(field)',field)
            setValue(field,+watch(field) + 1)
        }}> <i className="bi bi-plus-circle"></i></span>
    </div>
    }
    const funcSumCacul = () => {
        return caculPromo(itemLines, dataModal)||{}
    }
    const totalItemsPay = () => {
        let a = funcSumCacul()?.grossamount - amounttendered
        if (a < 0) {
            a = 0
        }
        return a
    }

    const [showPayTemplate,setShowPayTemplate] = useState(false)
    const [isShowCustomerInfo,setIsShowCustomerInfo] = useState(false)
    const [isShowPromotion,setIsShowPromotion] = useState(false)
    const [subMenu,setSubMenu] = useState('')
    // const [valuePrice,setValuePrice] = useState<any>('0')
    const [noofcovers,setNoofcovers] = useState<any>(null)
    const [promoCode,setPromoCode] = useState<any>(null)
    useEffect(() =>{
        // console.log('noofcovers',noofcovers)
        if(noofcovers) {
            let temp = JSON.parse(JSON.stringify(dataModal))
            temp.noofcovers = noofcovers
            setDataModal(temp)
        }
    },[noofcovers])
    const [isfirstlinecreated,setisfirstlinecreated] = useState<any>(false) 
    const [win,setWin] = useState<any>(window) 
    useEffect(() =>{
        // console.log('show',show)
        if(show) {
            // console.log('win',win.hasPopstateListener)
            if(!win.hasPopstateListener) {
                win.hasPopstateListener = true
                window.addEventListener('popstate', e=>{
                    // console.log('add listent popstate')
                    handleEventBack(e)
                },{ once: true });
            }
            
        }
        
    },[show])
    // const [isBack, setIsBack] = useState(false);
    const handleEventBack = async(e: any) => {
        // console.log('e',e)
        // console.log('dataModal',dataModal)
        // console.log('ccccccccccc')
        if(dataModal && dataModal.id) {
            // if(dataModal.lockinfo) {
            callLockTable({
                transactionid: dataModal.id,
                lockinfo: null,
            },setIsCallLock)
            // }
            if(dataModal && dataModal.id) {
                await funcCancelTrans()
            }
            
        }
        win.hasPopstateListener = false

        // setTimeout(()=>{
        //         console.log('remove event')
        //     window.removeEventListener("popstate", handleEventBack);
        // },200)
       
    };

    // useEffect(() => {
    //     return () => {
    //         setTimeout(()=>{
    //             (window as any).hasPopstateListener = false

    //             window.removeEventListener("popstate", handleEventBack);
    //         },200)
    //     };
    // },[]);
    const [isShowGuest,setIsShowGuest] = useState<any>(false)
    const handleClickAddGuest = () => {
        setIsShowGuest(true)
    }
    const rePrintKitchen = () => {
        findAndPrint(itemLines, {
            ...dataModal,
            message: note ? note : dataModal.message,
            staffInfo: staffInfo
        }, {
            PrinterList: storePrint.PrinterList,
            setPrinterlist: storePrint.setPrinterlist,
            staffInfo: staffInfo,
        },'IN LẠI')
    }
    const changeCustomer = () => {
        setIsShowCustomerInfo(true)
    }
    const addPromotion = () => {
        setIsShowPromotion(true)
    }
    
  
    const [modalSend,setModalSend] = useState(false)
    const funcChooseTable = (data: any) => {
        setTableInfo(data)
        setShowTable(false)
        createHospTrans && createHospTrans(data)
        
    }
    
    const templateDetailItem = (isShowDetail: any) => {     
        // console.log('toppingItems',toppingItems)   
        return <Offcanvas show={isShowDetail} className="offcanvas-custom shadow" onHide={handleClose} placement='end'>
        <Offcanvas.Header className=''>
            <h5 className="offcanvas-title fs-3" id="offcanvasLabel">{t('order-deep.change-quantity')}</h5>
            <button type="button" className="btn-close text-reset" onClick={handleClose} data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </Offcanvas.Header>
        <Offcanvas.Body className='pt-0'>
            <form onSubmit={handleSubmit(onSubmit)} className={clsx("col-12",{"": !isShowDetail})}>
                {toppingItems && isShowDetail && 
                <div>
                <div className={clsx("mb-6 d-flex gap-5",{"": isShowDetail})}>
                    {templateControlQuanlity('quantity_item',toppingItems.quantitydefault, (toppingItems.kitchenstatus && toppingItems.kitchenstatus != KITCHENSTATUS.notSent) || toppingItems.markedforitembenefit)}
                    <div className="d-flex gap-3">
                        {/* <span onClick={e=>{
                            setIndexEdit(-1)
                        }} className="btn btn-sm btn-light-primary w-100px min-w-100px "> <i className="bi bi-x fs-3"></i> Cancel</span> */}
                        {
                            toppingItems.kitchenstatus != KITCHENSTATUS.notSent ? <>
                            {(staffPermissionGroupInfo.isvoiditemsenttokitchen) &&
                                <span onClick={removeItemLine} className={clsx("btn btn-sm btn-light-danger w-100px min-w-100px ",)}> <i className="bi bi-slash-circle"></i> {t('order-deep.void')}</span>
                            }
                            </>:
                            <>
                            {(staffPermissionGroupInfo.isvoidline) &&
                                <span onClick={removeItemLine} className={clsx("btn btn-sm btn-light-danger w-100px min-w-100px ",)}> <i className="bi bi-slash-circle"></i> {t('order-deep.void')}</span>
                            }
                            </>
                        }
                        
                        {
                            (staffPermissionGroupInfo.ismanagerprivileges || !toppingItems.kitchenstatus || (toppingItems.kitchenstatus == KITCHENSTATUS.notSent)) && <button disabled={toppingItems.kitchenstatus && toppingItems.kitchenstatus != KITCHENSTATUS.notSent && (toppingItems.quantitydefault == watch('quantity_item'))} className="btn btn-sm btn-primary w-100px min-w-100px "> <i className="bi bi-plus-circle fs-3"></i> {t('order-deep.done')}</button>
                        }
                        
                    </div>
                </div>
                <div className="w-100">
                    <div className="title-tab w-100 mb-2 fs-4 fw-bold d-flex gap-5">
                        <span>{toppingItems.name}</span>
                        {
                            toppingItems.HospItemPrices &&
                            <span>{formatCurrent(toppingItems.HospItemPrices[0]?.unitprice)}</span>
                        }
                    </div>
                    {/* <p>{toppingItems.description}</p> */}
                    {
                        toppingItems.Customization && 
                        <div className="title-tab w-100 mb-5 fs-4 fw-bold">{toppingItems.Customization.headertext}</div>
                    }
                    <div>
                    {
                        toppingItems.Customization && 
                        toppingItems.Customization.CustomizationLines?.map((item: any,index: number) => (
                            <div key={index} className="cursor-pointer mb-3 d-flex gap-2 align-items-center">
                                <label className={clsx("form-check form-check-inline form-check-solid me-5")}>
                                    <input className="form-check-input" {...register(`toppingtype.${index}.value`, {
                                    onChange: (e) => {
                                        if(e.target.checked) {
                                            setValue(`quantity_topping.${index}.value`, 1)
                                        }
                                        else {
                                            setValue(`quantity_topping.${index}.value`, null)
                                        }
                                    }
                                    })} type="checkbox" value={item.id} />
                                    <div className="d-flex gap-5">
                                        <span>{item.hospitemname}</span>
                                        <span>{formatCurrent(item.price)}</span>
                                    </div>
                                </label>
                                {watch(`toppingtype.${index}.value`) == item.id && templateControlQuanlity(`quantity_topping.${index}.value`,1, toppingItems.kitchenstatus && toppingItems.kitchenstatus != KITCHENSTATUS.notSent)}
                                
                            </div>
                        ))
                    }
                    
                        <KTFormItem title={t('order-deep.note')} className="mb-4" isRequired isBLockLabel>
                            <textarea {...register('noteLine')} className="form-control form-control-sm " rows={3}></textarea>
                        </KTFormItem>
                        <div className="">
                            <div className="mb-4"><strong>{t('order-deep.quick-note')}:</strong></div>
                            <div className="d-flex gap-5 flex-wrap justify-content-space-between">
                            {
                                arrNote.map((i: any,index: number) =>(<span key={index} onClick={e=>{
                                    if(watch('noteLine')) {
                                        setValue('noteLine',[watch('noteLine'),i.name].join(', '))
                                    }
                                    else {
                                        setValue('noteLine',i.name)
                                    }
                                }} className="min-w-100px cursor-pointer bg-white btn btn-sm border border border-dark-subtle text-dark">{i.name}</span>))
                            }
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
                }
            
        </form>
        </Offcanvas.Body>
    </Offcanvas>
        
        
    }
    const handleRefeshMenu = () => {
        setIndexEdit(-1)
        setValue('quantity_topping',[])
    }
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [isShowLogsPrinter, setIsShowLogsPrinter] = useState(false);
    
    useEffect(() => {
        setIsShowDetail(indexEdit > -1)
    },[indexEdit])
    const handleClose = () => setIsShowDetail(false);
    const handleShow = () => setIsShowDetail(true);
    const [isShowAction,setIsShowAction] = useState(false)
    return <div id="modal_add_or_edit">
    {
        showTable && 
        <ModalChooseTable showTable={showTable} handleCloseModal={handleCloseModal} funcCallback={funcChooseTable}/>
    }
    <Offcanvas backdrop={false} show={isShowAction} className="offcanvas-custom shadow" onHide={(e: any)=>setIsShowAction(false)} placement='end' scroll={true}>
        <div className="fade offcanvas-backdrop show z-index-2" onClick={e=>setIsShowAction(false)}></div>
        <div className="position-relative z-index-3 bg-white h-100">
            <Offcanvas.Header className='h5 shadow d-flex flex-stack'>
                <span>{t('order-deep.order')} #{dataModal?.code}</span>
                <span>{dataModal?.receiptno}</span>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0'>
                
                <div className="shadow-none menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg-light-primary bg-white fw-bold fs-6 dropdown-menu show">
                    <div className="menu-item dropdown-item">
                        <span onClick={e=>{
                            setIsShowAction(false)
                            handleClickAddGuest()
                        }} className='d-block text-black border-bottom border-gray-300 px-3'>
                            <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-people-fill text-reset"></i>{t('order-deep.add-guest')}</span>
                        </span>
                        <span onClick={e=>{
                            setIsShowAction(false)
                            changeCustomer()
                        }} className='d-block text-black border-bottom border-gray-300 px-3'>
                            <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-person-circle text-reset"></i>{t('order-deep.add-member')}</span>
                        </span>
                        {
                            staffPermissionGroupInfo.ismanagerprivileges && 
                            <span onClick={e=>{
                                setIsShowAction(false)
                                rePrintKitchen()
                                
                            }} className='d-block text-black border-bottom border-gray-300 px-3'>
                                <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-clipboard2 text-reset"></i>{t('order-deep.reprint-kitchen-tickets')}</span>
                            </span>
                        }
                        
                        <span className='d-block text-black border-bottom border-gray-300 px-3' onClick={e=>{
                            setIsShowAction(false)
                            addPromotion()
                        }}>
                            <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-tag text-reset"></i>{t('order-deep.add-coupon')}</span>
                        </span>
                        <span onClick={e=>{
                            setIsShowAction(false)
                            funcCalculateBasket(dataModal)
                        }} className='d-block text-black border-bottom border-gray-300 px-3'>
                            <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-cart4 text-reset"></i>{t('order-deep.calculate-basket')}</span>
                        </span>
                        <span onClick={e=>{
                            setIsShowAction(false)
                            setValue('note','')
                            setIsShowNote(true)
                        }} className='d-block text-black border-bottom border-gray-300 px-3'>
                            <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-chat-right-text text-reset"></i>{t('order-deep.add-message')}</span>
                        </span>
                        {
                            (staffPermissionGroupInfo.isvoidtransaction) && 
                            <span className='d-block text-black border-bottom border-gray-300 px-3' onClick={e=>{
                                setIsShowAction(false)
                                const closeModalCancel = () => {
                                    refetchCurrent && refetchCurrent()
                                    setDataModal && setDataModal({})
                                    setItemLines && setItemLines([])
                                    setSelected([])
                                    setShowTable(false)
                                    setShow(false)
                                    setXQuanlity(1)
                                }
                                const callPrint = () => {
                                    let voidLines = dataModal.HospTransactionLines?.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.notSent)
                                    if(voidLines.length != 0) {
                                        findAndPrint(voidLines,{
                                            ...dataModal,
                                            message: note?note:dataModal.message,
                                            dataInfo: {type:'voided',title: 'HỦY MÓN',staffInfo: staffInfo}

                                        }, {
                                            PrinterList: storePrint.PrinterList,
                                            setPrinterlist: storePrint.setPrinterlist,
                                            staffInfo: staffInfo,
                                        },'HỦY MÓN')
                                    }
                                }
                                cancelOrderInModal(dataModal,closeModalCancel,callPrint, staffInfo)
                            }}>
                                <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-trash3 text-reset"></i>{t('order-deep.void-order')}</span>
                            </span>
                        }
                        
                        <span onClick={e=>{
                            setIsShowAction(false)
                            setIsShowMST(true)
                        }} className='d-block text-black border-bottom border-gray-300 px-3'>
                            <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-card-text text-reset"></i>{t('order-deep.e-invoice')}</span>
                        </span>
                        {
                            staffPermissionGroupInfo.ismanagerprivileges &&
                            <>
                                <span onClick={e=>{
                                    setIsShowAction(false)
                                    setIsShowLogsPrinter(true)
                                }} className='d-block text-black border-bottom border-gray-300 px-3'>
                                    <span className="menu-link d-flex gap-2 align-items-center py-4"><i className="fs-4 bi bi-printer text-reset"></i>{t('order-deep.logs-printer')}</span>
                                </span>
                            </>
                        }
                        
                    </div>
                </div>
            </Offcanvas.Body>
        </div>
    </Offcanvas>
    
    {/* <Modal show={modalSend} className="popup-in-popup" onHide={() => {
        setModalSend(false)
        setDataSentkitchen([])
        setValue('sentKitchen',[])
    }}>
        <Modal.Body className="bg-gray-200 pt-10">
            <div className="row">
                <div className="col-12"><h4>Select Production sections{t('order-deep.select-production-sections')}</h4></div>
                {
                    dataSentkitchen.map((item: any,index: number) => (
                        <div className="col-12 mb-3" key={index}>
                            <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                                <input className="form-check-input" type="checkbox" {...register(`sentKitchen.${index}`, {
                                    onChange: (e) => {} })} value={item.name} id={item.name} />
                                <label htmlFor={item.name}>{item.name} ({item.quantity})</label>
                            </div>
                        </div>
                    ))
                }
                
                <div className="col-12 text-end">
                    <button className="btn btn-sm btn-secondary me-2" onClick={e=>{
                        setModalSend(false)
                        setDataSentkitchen([])
                        setValue('sentKitchen',[])
                    }}>{t('orders-hub-deep.cancel')}</button>
                    <button onClick={actionSend} className="btn btn-sm btn-primary">{t('order-deep.send')}</button>
                </div>
            </div>
        </Modal.Body>
    </Modal> */}
    <Modal className="p-0" show={show} fullscreen={true} keyboard={false} onHide={() => setShow(false)}>
    {
        dataModal && 
        <Modal.Body className="bg-gray-200 p-2">
    <div className="row g-0">
        <div className="col-sm-5 col-lg-4 col-12 pe-2 position-relative">
            <div className={clsx("p-0 card shadow position-sticky top-2 z-index-1")}>
                <h6 className="text-black fs-3 d-flex flex-stack px-3 pt-3">
                    <span>
                        {/* <i onClick={e=>handleCloseModal()} className="bi bi-arrow-left fs-3 text-black p-3 ps-0 cursor-pointer"></i> */}
                        {dataModal?.tablename} - {dataModal.sectionname}
                    </span>
                    <div className="d-flex gap-2 flex-center"> 
                        {dataModal.receiptno && 
                            <span className="">{dataModal.receiptno} </span>
                        }
                    </div>
                </h6>
                <div className={clsx({"loading-calculate":isloading})}>
                    {
                        isloading &&
                        <div className="bg-blur"><div className="loader-cal"></div></div>
                    }
                    <DetailItem {...dataModal} dataMenu={dataMenuStructures.MenuStructureLines} mobileTransBenefitLine={mobileTransBenefitLine} isEdit={indexEdit} reCallFunc={getInforTopping} funcUpsert={funcUpsertwithdetails} HospTransactionLines={itemLines} setitemLines={setItemLines} selected={selected} setSelected={setSelected} isModal={true} note={note}/>
                    <div className="wrap-footer-inf">
                        <MemberInforAndNote note={note} {...dataModal} setDataModal={setDataModal} funcCalculateBasket={funcCalculateBasket} />
                        <div className="p-3">
                            <div className="d-flex gap-2">
                                <button onClick={e=>{

                                }} className="w-100 btn btn-sm border border border-dark-subtle text-dark px-1">{t('order-deep.discount')}</button>
                                <button onClick={e=>{
                                    setIsShowAction(true)
                                }} className="w-100 btn btn-sm border btn-secondary border border-dark-subtle text-dark px-1"><i className="fas fa-list-ul text-dark me-1"></i>{t('order-deep.action')}</button>
                                
                                <button onClick={e=>{
                                    let {store} = user
                                    prePrintReceipt(itemLines, {
                                        ...dataModal,
                                        store: store
                                    }, {
                                        PrinterList: storePrint.PrinterList,
                                        setPrinterlist: storePrint.setPrinterlist,
                                        staffInfo: staffInfo,
                                    })
                                    popupMessage({ icon: 'success', autoClose: true })
                                }} className="btn btn-sm btn-gray w-100 px-1"><i className="bi bi-printer me-1"></i>{t('order-deep.pre-print')}</button>
                                
                            </div>
                        </div>
                        <PromotionAndSum {...dataModal} setDataModal={setDataModal} isEdit={indexEdit} note={note} selected={selected} HospTransactionLines={itemLines}/> 
                        <div className="p-3">
                            <div className="d-flex gap-2">
                                <button onClick={actionStay} disabled={selected.length == 0} className="btn btn-sm btn-warning w-100 px-1"><i className="bi-circle-icon p-0 bi bi-chevron-double-right me-1"></i>{t('order-deep.stay')} {selected.length !=0 && `(${selected.length})`}</button>
                                <button onClick={funcSendOrder} disabled={itemLines.length == 0} className="btn btn-sm btn-primary w-100 px-1"><i className="fs-9 bi bi-send-fill me-1"></i>{t('order-deep.send')}</button>
                                {
                                    staffPermissionGroupInfo.isaddpayment && (totalItemsPay() !=0 && itemLines.length != 0) &&
                                    <button className="btn btn-sm btn-info w-100 px-1" onClick={e=>{
                                        setShowPayTemplate(true)
                                    }}> {t('order-deep.lines-items-pay-price', {
                                        lines: itemLines.length,
                                        price: formatCurrent(totalItemsPay())
                                    })}</button>
                                }
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div className="col-sm-7 col-lg-8 col-12">
            {isShowDetail && templateDetailItem(isShowDetail)}
            
            <div className="mb-3 d-flex flex-stack gap-3">
                <SearchTable returnFunc={searchFunc} className="w-100" classInput="rounded-5"/>
                <span onClick={e=>handleCloseModal()} className="p-2 text-nowrap cursor-pointer d-flex flex-center gap-2 fs-5">{t('close')} <i className="bi fs-2x text-danger bi-x-circle"></i></span>
            </div>
            {/* <SliderSlick menus={menus} items={items} groups={groups}/> */}
            
            {/* <div className="d-flex gap-3 mb-4 align-items-center">
                <span>Quanlity: </span>
                {
                    xTags.map((i: any) => (
                        <span key={i} onClick={e=> setXQuanlity(i)} className={clsx(`fs-5 px-4 cursor-pointer badge badge-${xQuanlity == i ?'primary':'secondary'}`)}>{i}x</span>
                    ))
                }
            </div> */}
            <div className="wrap-slider-order d-flex gap-2">
                <div className="fixed-left-order w-25 h-100 wrap-item-y pe-1">
                    {/* <div className="title-tab w-100 btn btn-sm bg-primary bg-opacity-10 mb-2 opacity-0">title</div> */}
                    {
                        menus && 
                        menus.map((item: any,index: number) => (
                            <div onClick={e=>handleTabMenuActive(item)} key={index} className={clsx("cursor-pointer fs-5 col bg-light-orange px-3 py-5 rounded-2 w-100 mb-2",{'border border-1 border-orange border-solid active border-2': menuActive == item.menukey,'': menuActive != item.menukey})}>
                                {item.navigationlabel}
                            </div>
                        ))
                    }
                    
                </div>
                <div className="aside-right-order w-75 h-100">
                    {
                        dataGroups && <>
                        <div className="tab-category d-flex gap-2">
                        {
                            <div
                            className='card d-flex flex-row text-nowrap scrollx-auto tab-custom-hub w-100 mb-3'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-width='auto'
                            data-kt-scroll-offset='0'
                        >
                            {dataGroups?.map((item: any, index: number) => {
                            return item.id == 'clear'?
                            <span
                                key={index}
                                id={item.id}
                                onClick={() => {
                                    handleTabMenuActive(menus[0])
                                }}
                                className={clsx(
                                ' cursor-pointer item-tab fs-5',
                                subMenu != item.id && '',
                                subMenu == item.id && 'active',
                                index == 0 && ''
                                )}>
                                {item.navigationlabel} <i className="ms-1 text-reset bi bi-x-circle"></i>
                            </span>
                            :
                            <span
                                key={index}
                                id={item.id}
                                onClick={() => {
                                    setSubMenu(item.id)
                                    handleRefeshMenu()
                                }}
                                className={clsx(
                                ' cursor-pointer item-tab fs-5',
                                subMenu != item.id && '',
                                subMenu == item.id && 'active',
                                !item.displayTitle && item.type == 'custom' && 'd-none',

                                index == 0 && ''
                                )}>
                                {item.navigationlabel}
                            </span>
                            })}
                        </div>   

                        }
                        </div>

                        {
                        dataGroups.map((item: any,index: number) => (
                        <div key={index} className="tab-category">
                            <div className="item-order-custom">
                                <div className="wrap-item-y d-flex row g-0 flex-wrap">
                                    {
                                        subMenu == item.id && <>
                                        {
                                            item.hostitems && item.hostitems.length !=0 ? item.hostitems.map((i: any,ind: number) => {
                                                return <div className="col-3 px-1 mb-2" key={ind}>
                                                    <div onClick={e=> {
                                                        if(i.type == 'note') {
                                                            if(i.id == 'free-text') {
                                                                setValue('note','')
                                                                setIsShowNote(true)
                                                            }
                                                            else {
                                                                let ids = dataModal?.kots?.map((i: any) => i.id)||[]
                                                                funcAddNote(i.name, setNote,ids,dataModal?.id)
                                                            }
                                                            return
                                                        }
                                                        if(!i.itemno && i.code) {
                                                            i.itemno = i.code  
                                                        }
                                                        delete i.id
                                                        // console.log('Customization',i.Customization)
                                                        if(i.Customization && i.Customization.CustomizationLines && i.Customization.CustomizationLines?.length != 0 && allowpopupcustomization) {
                                                            setIsShowDetail(true)
                                                            // console.log('itemLines?.length',itemLines?.length)
                                                            getInforTopping(i,itemLines?.length)
                                                            
                                                        }
                                                        else {
                                                            // console.log('new i',i)
                                                            delete i.lineno
                                                            delete i.kitchenstatus
                                                            delete i.message
                                                            delete i.kotline
                                                            
                                                            i.quantity = 1
                                                            addItemLine(i)
                                                            handleRefeshMenu()
                                                        }
                                                        
                                                    }} className={clsx(`cursor-pointer border border-1 border-primary bg-light-primary p-2 rounded-2 wrap-item-menu ${i.name.length > 50 ? '':'d-flex flex-center text-center'}`,)}>
                                                    {i.name}
                                                </div> 
                                                </div>
                                            })
                                            :
                                            <div className="border border-1 border-warning col bg-light-warning px-3 py-3 rounded-2 col-3 mb-2">
                                                No data
                                            </div>
                                        }
                                        </>
                                        
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        ))
                        }
                        </>
                        
                    }
                    
                </div>
            </div>
            
        </div>
        
    </div> 
    </Modal.Body>
    }
    
  </Modal>
  {
    staffPermissionGroupInfo.isaddpayment &&
    <ModalPay show={showPayTemplate} handleCloseModal={handleCloseModal} dataModal={dataModal} setDataModal={setDataModal} setShow={setShowPayTemplate} />
  }
  <ModalMessage isShowNote={isShowNote} setIsShowNote={setIsShowNote} setNote={setNote} callFuncAddMess={funcAddNote} ids={dataModal?.kots?.map((i: any) => i.id)||[]} idHosp={dataModal?.id}/>
  <ModalAddGuest isShow={isShowGuest} setIsShow={setIsShowGuest} setValueData={setNoofcovers} valueData={dataModal?.noofcovers} idHosp={dataModal?.id}/>
  <ModalChangeCustomer funcCalculateBasket={funcCalculateBasket} isShow={isShowCustomerInfo} setIsShow={setIsShowCustomerInfo} idHosp={dataModal?.id} dataModal={dataModal} setValueData={setDataModal}/>
  <ModalAddPromotion isShow={isShowPromotion} setIsShow={setIsShowPromotion} idHosp={dataModal?.id} onSubmitFunc={funcCalculateBasket} setValueData={setPromoCode} dataModal={dataModal}/>
  <ModalAddMST isShow={isShowMST} setIsShow={setIsShowMST} dataModal={dataModal} setValueData={setDataModal}/>
  <CanvasViewlogsPrinter isShow={isShowLogsPrinter} setIsShow={setIsShowLogsPrinter} />
</div>
    
}