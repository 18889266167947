/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) root.style.height = '100%'
    return () => {
      if (root) root.style.height = 'auto'
    }
  }, [])

  return (
    < /* className='d-flex flex-column flex-lg-row  bg-auth text-white ' */>
      {/* begin::Body */}
      {/* <div className='d-flex flex-column w-100 py-5 order-2 order-lg-1'> */}
        {/* begin::Form */}
          {/* begin::Wrapper */}
            <Outlet />
          {/* end::Wrapper */}
        {/* end::Form */}
      {/* </div> */}
      {/* end::Body */}
    </>
  )
}

export { AuthLayout }

